<template lang="pug">
  form(autocomplete="off" v-if="!loading").PasswordCreationForm
    h2 Создание нового пароля
    .PasswordCreationForm__Text Придумайте надежный пароль

    a-form-item(
      :validate-status="hasErrors"
    )
      a-input-password(
        v-model="firstPass"
        size="large"
        placeholder="Пароль"
      )

    a-form-item(
      :validate-status="noMatching"
      :help="!noMatching === 'error' ? 'Пароли не совпадают' : ''"
    )
      a-input-password(
        v-model="secondPass"
        size="large"
        placeholder="Подтверждение пароля"
      )

    .PasswordCreationForm__Note
      b Пароль должен содержать
      ul
        li(
          :class="!firstPass ? '' : lengthCSSClass"
        ) не менее 8 символов
        li(
          :class="!firstPass ? '' : lowLettersCSSClass"
        ) прописные латинские буквы
        li(
          :class="!firstPass ? '' :  capLettersCSSClass"
        ) строчные латинские буквы
        li(
          :class="!firstPass ? '' : specSymbCSSClass"
        ) цифры

    a-row.submit-row
      Button(
        text="Сохранить"
        type="primary"
        :disabled="isDisabled"
        @on-submit="onEnter"
      )
</template>
<script >
import Vue from 'vue';

import Button from '@/components/ui/Button.vue';
import { validate } from '@/utils/validation';
import {localStorageService} from "@/services/localStorage";
import {mapActions} from "vuex";

export default Vue.extend({
  name: 'PasswordCreationForm',

  components: {
    Button,
  },

  data() {
    return {
      firstPass: '',
      secondPass: '',
      largeScreenSettings: { span: 12, offset: 6 },
      loading: true
    };
  },

  computed: {
    hasErrors() {
      if (!this.firstPass.length) {
        return 'success';
      }
      return (
        this.firstPass && this.firstPass.length > 7 &&
        validate(['lowLetters', 'capLetters', 'number'], this.firstPass)
      ) ? 'success' : 'error' ;
    },
    firstPassRules() {
      return {
        moreThan8Chars: this.firstPass.length >= 8,
        lowLetters: validate(['lowLetters'], this.firstPass),
        capLetters: validate(['capLetters'], this.firstPass),
        specSymb: validate(['number'], this.firstPass),
      };
    },
    lengthCSSClass() {
      if (this.firstPass && this.firstPass.length < 8) {
        return 'rule-failed';
      }
      return 'rule-success';
    },
    lowLettersCSSClass() {
      if (this.firstPass && !(this.firstPassRules.lowLetters)) {
        return 'rule-failed';
      }
      return 'rule-success';
    },
    capLettersCSSClass() {
      if (this.firstPass && !(this.firstPassRules.capLetters)) {
        return 'rule-failed';
      }
      return 'rule-success';
    },
    specSymbCSSClass() {
      if (this.firstPass && !(this.firstPassRules.specSymb)) {
        return 'rule-failed';
      }
      return 'rule-success';
    },
    emptyPass() {
      if (this.firstPass && this.secondPass) {
        return false;
      }
      return true;
    },
    noMatching() {
      if (this.secondPass) {
        return this.firstPass !== this.secondPass ? 'error' : 'success';
      }
      return 'success';
    },
    isDisabled() {
      if (!this.firstPass.length || !this.secondPass.length) {
        return true;
      }
      return this.hasErrors === 'error' || this.noMatching === 'error';
      }
  },

  methods: {
    ...mapActions("auth", ["logout"]),
    onEnter() {
      this.$emit('create', this.secondPass);
    },
  },
  async mounted() {
    localStorageService.remove("AUTH_TOKEN");
    localStorageService.remove("REFRESH_TOKEN");
    await this.logout();
    this.loading = false
  }

});
</script>
<style lang="scss" scoped>
.PasswordCreationForm {
  margin-top: -90px;

  @media screen and (max-width: 1200px) {
    margin-top: 0;
  }
}

.PasswordCreationForm__Note {
  font-size: 15px;
  line-height: 1.7;

  ul {
    padding-left: 18px;
    padding-top: 3px;
  }
}

.PasswordCreationForm__Text {
    margin-bottom: 40px;
    margin-top: -27px;
    color: var(--dark-gray);
    font-size: 17px;
}

.submit-row {
  margin-top: 32px;
}

.input-error {
  border: 2px solid red !important;
  background-color: #fff6f5 !important;
  border-radius: 4px;
}

.rule-failed {
  color: red;
}

.rule-success {
  color: green;
}
</style>
