export default {
    isLoggedIn: (state: any): boolean => !!state.access,
    hasErrors: (state: any): boolean => state.error,
    errorText: (state: any): boolean => state.errorText,
    getPermissions: (state: any): object => state.permissions,
    canAccess: (state: any) => (flags: string[] = []) => {
      if (!state.permissions) {
        return false;
      }
      const perms = Object.keys(state.permissions).filter(perm => Boolean(state.permissions[perm]));
      return flags.every(flag => perms.includes(flag));
    },
    canEdit: (state: any) => (flags: string[] = []) => {
      if (!state.permissions) {
        return false;
      }
      const editablePerms = Object.keys(state.permissions).filter(perm => state.permissions[perm] === 2);
      return flags.every(flag => editablePerms.includes(flag));
    },
};
