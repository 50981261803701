var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"RestorePasswordForm",attrs:{"form":_vm.form,"autoComplete":"off"},on:{"submit":_vm.handleSubmit}},[_c('h2',[_vm._v("Сброс пароля")]),_c('div',{staticClass:"RestorePasswordForm__Text"},[_vm._v("Введите E-mail на который зарегистрирован Ваш аккаунт, и мы отправим на него инструкцию по сбросу пароля")]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'email',
        {
          rules: [
            {
              type: 'email',
              message: 'Некорректный e-mail адрес'
            },
            {
              required: true,
              message: 'Поле обязательно для заполнения'
            } ]
        }
      ]),expression:"[\n        'email',\n        {\n          rules: [\n            {\n              type: 'email',\n              message: 'Некорректный e-mail адрес'\n            },\n            {\n              required: true,\n              message: 'Поле обязательно для заполнения'\n            },\n          ]\n        }\n      ]"}],attrs:{"placeholder":"E-mail"},on:{"change":_vm.emailChange}})],1),(_vm.error)?_c('div',{staticClass:"Error"},[_vm._v("Невозможно сбросить пароль")]):_vm._e(),_c('a-button',{staticClass:"RestorePasswordForm__Button",attrs:{"type":"primary","html-type":"submit","disabled":_vm.hasErrors(_vm.form.getFieldsError()) || !_vm.form.getFieldValue('email')}},[_vm._v("Сбросить пароль")]),_c('div',{staticClass:"text-center"},[_c('BackToLogin')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }