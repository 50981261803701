import {historyOrders} from "@/store/modules/historyOrders/types";

export default {
  dateOptions: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  },
  historyOrders: [] as historyOrders[],
  statuses: [] as string[],
  historyOrdersRX: [] as historyOrders[],
  statusesRX: [] as string[],
};
