import api from "@/api/orderRX";
import {OrderRXFiltersRequest} from "@/store/modules/orderRX/types";

export default {
  async getOrderRXFilters({ commit }: any, data: OrderRXFiltersRequest) {
    try {
      const res = await api.getOrderRXFilters(data);
      commit('setOrderRXFilters', res.data);
    } catch (error) {
      // console.log(error);
    }
  },
  async addNewOrder({ commit }: any, data: object) {
    try {
      return await api.addNewOrder(data);
    } catch (error) {
      return { error: error.response.data };
    }
  },
}
