type StateType = {
  orders: {data: object}
  itemsCount: number | string
}

function countOrdersAndItems(state:StateType):any {
  const arr: object[] = Object.values(state.orders.data)
  let itemsCount = 0
  arr.forEach((el: any) => {
    el.items.forEach((item:any) => {
      itemsCount += item.count
    })
  })

  return `${arr.length}/${itemsCount}`
}

export default {
    setOrders(_state: any, value: object[]) {
        _state.orders = value;
    },

    setDeliveryAddresses(_state: any, value: object[]) {
        _state.deliveryAddresses = value;
    },

    setDeliveryCompanies(_state: any, value: object[]) {
       _state.deliveryCompanies = value;
    },

  addOrderToCart({orders}: any, value: string) {
    if(!orders.includes(value)) {
      orders.push(value)
    }
  },
  deleteOrderToCart(_state: any, value: string) {
    delete _state.orders.data[value]
  },
  setCurrentOrder(_state: any, value: string) {
    _state.currentOrder = value
  },
  setItemsCountInCart(_state: any) {
    if(_state.currentOrder) {
      const order: any = _state.orders.data[_state.currentOrder]
      if(order) {
        const items: any = order.items

        const itemCount: any = items.reduce((acc: number, el: any) => {
          return acc + el.count
        }, 0)
        _state.itemsCount = itemCount
      } else {
        _state.itemsCount = countOrdersAndItems(_state)
      }
    } else {
      _state.itemsCount = countOrdersAndItems(_state)
    }
  },
};
