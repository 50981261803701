import values from './data/data'

export default {
  getTabsColoringLencor: (_state: any) => {
    return values.tabsColoringLencor
  },
  getTabsColoringBBGR: (_state: any) => {
    return values.tabsColoringBBGR
  },
  getColoringLencor: (_state: any) => {
    return values.coloringLencor
  },
  getColoringBBGR: (_state: any) => {
    return values.coloringBBGR
  },
  getMirrorCoatingLencor: (_state: any) => {
    return values.mirrorCoatingLencor
  },
  getMirrorCoatingBBGR: (_state: any) => {
    return values.mirrorCoatingBBGR
  },
  getTabsColorXperio: (_state: any) => {
    return values.tabsColorXperio
  },
  getTabsColorTransitions: (_state: any) => {
    return values.tabsColorTransitions
  },
  getColorXperio: (_state: any) => {
    return values.colorXperio
  },
  getColorTransitions: (_state: any) => {
    return values.colorTransitions
  },
  getColorTransitionsXTRActive: (_state: any) => {
    return values.colorTransitionsXTRActive
  },
  getColorSunsensy: (_state: any) => {
    return values.colorSunsensy
  },
};
