export const PRIV = {
  // Сотрудники, клиенты, роли
  emp_adm: 'emp_adm', // Администрирование сотрудников
  role_adm: 'role_adm', // Администрирование ролей
  client_adm: 'client_adm', // Администрирование клиентов

  // Заказы, каталог
  acc_order_wrh_les: 'acc_order_wrh_les', // Доступ к заказу складских линз
  acc_orders_rx: 'acc_orders_rx', // Доступ к заказу RX
  acc_order_history: 'acc_order_history', // Доступ к истории заказов
  acc_directory: 'acc_directory', // Каталог

  // Цены, остатки
  rec_price_vis: 'rec_price_vis', // Видимость рекомендованных цен
  price_vis_order: 'price_vis_order', // Видимость цен в подтвержденных заказах
  warehouse_vis: 'warehouse_vis', // Видимость складских остатков

  // Другие права
  acc_main_page: 'acc_main_page', // Доступ к главной странице
  acc_contact: 'acc_contact', // Доступ к контактам
  acc_polls: 'acc_polls', // Доступ к опросам
  acc_fin_info: 'acc_fin_info', // Доступ к финансовой информации
  mrk_prm: 'mrk_prm', // Маркетинговые акции и материалы
  feedback: 'feedback', // Обратная связь
  profile_adm: 'profile_adm', // Администрирование профиля
  show_client: 'show_client' // Виден клиенту
};
