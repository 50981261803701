










import Vue from 'vue';

export default Vue.extend({
  name: 'Button',

  props: {
    text: {
      type: String,
      default: 'Button',
      required: false,
    },
    type: {
      type: String,
      default: 'secondary',
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    buttonClass() {
      return this.type === 'primary' ? 'primary clickable' : 'secondary clickable';
    },
    textClass() {
      return this.type === 'primary' ? 'primary-text' : 'secondary-text';
    },
  },

  methods: {
    onClick() {
      if (!(this as any).disabled) {
        this.$emit('on-submit');
      }
    },
  },
});
