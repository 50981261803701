import client from '@/api/client';

const BASE_API = 'v1/promotions';
const GET_PROMO_CODES = 'v1/promocodes';

export default {
  getPromotions(clientId: string): Promise<any> {
    return client.get(`${BASE_API}?client=${clientId}`);
  },
  getPromotion(data: any): Promise<any> {
    return client.get(`${BASE_API}/${data.promotionId}?client=${data.clientId}`);
  },
  generateMaterialPdf(materialId: string): Promise<any> {
    return client.post(`${BASE_API}/generate-material-pdf/${materialId}`);
  },
  generateOfferPdf(promotionId: string): Promise<any> {
    return client.post(`${BASE_API}/${promotionId}/generate-offer-pdf`);
  },
  acceptOffer(data: object): Promise<any> {
    return client.post(`${BASE_API}/accept-offer`, data);
  },
  getPromoCodes(userId: string): Promise<any> {
    return client.get(`${GET_PROMO_CODES}?user_guid=${userId}`);
  },
}
