import api from "@/api/financialInformation";

export default {
  async getFinancialInformation({ commit }: any, id: string) {
    try {
      const res = await api.getFinancialInformation();
      commit("setFinancialInformation", res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
};
