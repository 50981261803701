import axios from 'axios';
import client from '@/api/client';
import {OrderRXFiltersRequest} from "@/store/modules/orderRX/types";

const BASE_VUE_APP_URL = process.env.VUE_APP_URL;
const order = axios.create({
  timeout: 30000,
  baseURL: BASE_VUE_APP_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  maxContentLength: 10000,
});

const BASE_API = 'api';

const GET_ORDERRX_FILTERS = `order/api/v1/catalog_rx/products/`
const ADD_NEW_ORDER = `${BASE_VUE_APP_URL}/order/api/v1/orders/rx/update/`

export default {
  getOrderRXFilters(data: OrderRXFiltersRequest) {
    let newData = data
    if(data.Property === 'missing') {
      newData = {...data, Property: ''}
    }
    return order.post(GET_ORDERRX_FILTERS, newData)
  },
  addNewOrder(data: object) {
    return client.post(ADD_NEW_ORDER, data)
  },
}
