<template lang="pug">
  div
    .logo
      img(alt="MOK", src="../assets/img/Logo_MOK.webp")
    router-view.authContainer
</template>

<script>
  import Vue from "vue";

  export default Vue.extend({
    name: "AuthLayout",
  });
</script>

<style lang="scss" scoped>
  .logo {
    position: absolute;
    top: 30px;
    left: 30px;

    @media screen and (max-width: 440px) {
      top: 20px;
      left: 15px;
    }
  }

  .authContainer {
    padding: 109px 80px 30px;
    min-height: 100vh;
    max-width: 1260px;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
      padding-left: 15px;
      padding-right: 15px;
      max-width: 430px;
    }
  }
</style>
