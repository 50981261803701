import { RouteConfig } from 'vue-router';
import { PRIV } from '@/router/rights';

const routes: Array<RouteConfig> = [
  {
    path: '/marketing/promotions',
    name: 'marketing-promotions',
    component: () => import("@/views/Marketing/Promotions/Promotions.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.client_adm],
      layout: 'home',
      title: 'Маркетинговые акции',
    },
  },
  {
    path: '/marketing/promotions/:id',
    name: 'promotion-detail',
    component: () => import("@/views/Marketing/Promotions/PromotionDetail.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.client_adm],
      layout: 'home',
      title: 'Подробно об акции',
    },
  },
  {
    path: '/marketing/promotions/:id/materials',
    name: 'promotion-materials',
    component: () => import("@/views/Marketing/Promotions/PromotionMaterials.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.client_adm],
      layout: 'home',
      title: 'Маркетинговые материалы',
    },
  },
  {
    path: '/marketing/promotions/:id/offer',
    name: 'promotion-offer',
    component: () => import("@/views/Marketing/Promotions/PromotionOffer.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.client_adm],
      layout: 'home',
      title: 'Договор оферты',
    },
  },
  {
    path: '/marketing/promo-codes',
    name: 'promo-codes',
    component: () => import("@/views/Marketing/PromoCodes/PromoCodes.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.client_adm],
      layout: 'home',
      title: 'Бонусные коды',
    },
  },
  {
    path: '/marketing/materials',
    name: 'marketing-materials',
    component: () => import("@/views/Marketing/Materials.vue"),
    meta: {
      requiresAuth: true,
      flags: [PRIV.client_adm],
      layout: 'home',
      title: 'Маркетинговые материалы',
    },
  },
  {
    path: '/marketing/editor',
    name: 'editor',
    component: () => import('@/views/Marketing/Editor.vue'),
    meta: {
      requiresAuth: true,
      flags: [],
      layout: 'home',
      title: 'Персонализация маркетинговых материалов'
    },
  },
];

export default routes;
