import client from '@/api/client';
import axios from "axios";

const BASE_API = 'api/v1';

const BASE_VUE_APP_URL = process.env.VUE_APP_URL;

const cart = axios.create({
  timeout: 30000,
  baseURL: BASE_VUE_APP_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  maxContentLength: 10000,
});

const CART_GET_DELIVERY_ADDRESSES = `${BASE_API}/`;
const CART_GET_DELIVERY_COMPANIES = `${BASE_API}/`;
const CART_GET_ORDERS = `${BASE_VUE_APP_URL}/order/api/v1/cart/get_orders/`
const CART_UPDATE_ORDER = `${BASE_VUE_APP_URL}/order/api/v1/cart/update_finalize_order/`;
const CART_SUBMIT_ORDER = `${BASE_VUE_APP_URL}/order/api/v1/cart/finalize_order/`;
const NOMENCLATURE_EMPTY_GET_OLD_ORDERED = `/order/api/v1/orders/warehouse/last_orders/`

export default {
  getOrders(data: any) {
    return cart.post(`${CART_GET_ORDERS}`,{...data}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('AUTH_TOKEN')}`,
      },
    })
  },
  getDeliveryAddresses() {
    return client.post(CART_GET_DELIVERY_ADDRESSES);
  },
  getDeliveryCompanies() {
    return client.post(CART_GET_DELIVERY_COMPANIES);
  },
  updateOrder(data: object) {
    return client.post(CART_UPDATE_ORDER, data);
  },
  submitOrder(data: object) {
    return client.post(CART_SUBMIT_ORDER, data);
  },
  getNomenclatureEmptyGetOldOrdered(data: object) {
    return cart.get(NOMENCLATURE_EMPTY_GET_OLD_ORDERED, {params: data})
  },
}
