





















import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';

import Button from '@/components/ui/Button.vue';

type DataTypes = {
  login: string;
  password: string;
  largeScreenSettings: any;
};

export default Vue.extend({
  name: 'AuthForm',

  components: {
    Button,
  },

  data() {
    return {
      login: '',
      password: '',
      largeScreenSettings: { span: 12, offset: 6 },
    };
  },

  computed: {
    ...mapGetters('auth', ['hasErrors', 'errorText']),
    isDirty() {
      return !((this as any).login && (this as any).password);
    },
  },

  methods: {
    ...mapMutations('auth', ['setError', 'setErrorText']),

    handleSubmit(e: any) {
      e.preventDefault();
      const data = {
        email: (this as any).login,
        password: (this as any).password,
      };
      this.$emit('authenticate', data);
    },
  },

  watch: {
    login() {
      (this as any).setError(false);
      (this as any).setErrorText("");
    },
    password() {
      (this as any).setError(false);
      (this as any).setErrorText("");
    },
  },
});
