
export default {
    setUsers(_state: any, users: any) {
        _state.users = users;
    },
    setValue(_state: any, obj: any) {
        const key = Object.keys(obj)[0];
        _state[key] = obj[key];
    }
};
