import api from "@/api/catalog";

export default {
    async getNomenclatureLens({ commit }: any, data: any) {
        try {
          if (data.Stigmatic === 'stigmatic') {
            return await api.getNomenclatureStigmaLens(data);
          }
          return await api.getNomenclatureAstigmaLens(data);
        } catch (error) {
          return { error: 'error' };
        }
    },
    async getNomenclatureEmptyLenses({ commit }: any, data: any) {
      try {
        const res = await api.getNomenclatureEmptyLenses(data);
        commit('setNomenclatureEmptyLenses', res)
        return res
      } catch (error) {
        return error
      }
    },
    async getNomenclatureExtendedFilters({ commit }: any, data: object) {
        try {
            const res = await api.getNomenclatureExtendedFilters(data);
            commit('setNomenclatureExtendedFilters', res);
        } catch (error) {
            console.error('CATALOG NOMENCLATURE EXTEND GET FILTERS', error);
        }
    },
    async getNomenclatureExtendedLenses({ commit }: any, data: any) {
        try {
            const { page, ...filters } = data;
            const res = await api.getNomenclatureExtendedLenses({
                filters: filters,
                page: page
            });
            return res.data
        } catch (error) {
            return { error: 'error' };
        }
    },

    async NomenclatureGetBalanceByLenses({ commit }: any, data: string) {
      try {
        return await api.NomenclatureGetBalanceByLenses(data);
      } catch (error) {
        return {error: 'error'};
      }
    },
    async getSegments({ commit }: any, data: any) {
      try {
        const res = await api.getSegments(data);
        commit('setSegments', res.data);
      return res.data
      } catch (error) {
        return { error };
      }
    },
    async saveSegments({ commit }: any, data: any) {
      try {
        return await api.saveSegments(data);
      } catch (error) {
        return { error };
      }
    },
    async NomenclatureChangeCountLenses(context: any, data: object) {
      try {
        return await api.NomenclatureChangeCountLenses(data);
      } catch (error) {
        return { error };
      }
    },
    async NomenclatureGetOrder(context: any, data: object) {
      try {
        return await api.NomenclatureGetOrder(data);
      } catch (error) {
        return { error: 'error' };
      }
    },
    async NomenclatureGetAllOrders(context: any, data: object) {
      try {
        return await api.NomenclatureGetAllOrders(data);
      } catch (error) {
        return { error: 'error' };
      }
    },
    async deleteOrder({ dispatch }: any, order: object) {
      try {
        return await api.deleteOrder(order);
      } catch (error) {
        return { error: 'error' };
      }
    },
    async repeatOrder({ dispatch }: any, order: object) {
      try {
        return await api.repeatOrder(order);
      } catch (error) {
        return { error: 'error' };
      }
    },
};
