import Vue from 'vue'
import {OrderRXFiltersResult, setLensParametersPayloadType} from "@/store/modules/orderRX/types";

export default {
  setTriggerPage(_state: any, data: string) {
    _state.triggerPage = data;
  },
  setLens({ order }: any, data: object) {
    order.lens = data;
  },
  setChosenLens(_state : any, id: string) {
    _state.chosenLens = id;
  },
  setOrderRXFilters(_state: any, data: OrderRXFiltersResult) {
    _state.orderRXFilters = data.filters;
    _state.orderRXLenses = data.results;
    _state.orderRXRules = data.design_rules;
  },
  setOrderRXFiltersChecked(_state: any, data: any) {
    _state.orderRXFiltersChecked[data.label] = data.value;
  },
  setLensManufacturer(_state: any, data: string) {
    _state.manufacturer = data
  },
  setLensParameters(_state: any, payload: setLensParametersPayloadType) {
    Vue.set(_state.lensParameters, payload.key, payload.data)
  },
  setBaseValue({ order }: any, data: string | number) {
    order.baseValue = data;
  },
  setDiameter({ order }: any, data: string | number) {
    order.diameter = data;
  },
  setFrameType({ order }: any, data: string | number) {
    order.frameType = data;
  },
  setFrameTypeValue({ order }: any, data: string | number) {
    order.frameTypeValue = data;
  },
  setFrameModel({ order }: any, data: string) {
    order.frameModel = data;
  },
  setProductionType({ order }: any, data: string | number) {
    order.productionType = data;
  },
  setRepairType({ order }: any, data: string[]) {
    order.repairType = data;
  },
  setPaintingType({ order }: any, data: string | number) {
    order.paintingType = data;
  },
  setAdditionalType({ order }: any, data: string[]) {
    order.additionalType = data;
  },
  setFrameColoring({ order }: any, data: boolean) {
    order.frameColoring = data;
  },
  setUVProtect({ order }: any, data: boolean) {
    order.UVProtect = data;
  },
  setComments({ order }: any, data: string) {
    order.comments = data;
  },
  setFrameForm({ order }: any, data: object) {
    order.frameForm = data;
  },

  setArm({ order }: any, data: object) {
    order.arm = data;
  },
  setRecipeDetails({ order } : any, data: any) {
    order.recipeDetails.recipeDetailsTableRE = data.recipeDetailsTableRE.map((el: any) => ({title: el.title, value: el.value}))
    order.recipeDetails.recipeDetailsTableLE = data.recipeDetailsTableLE.map((el: any) => ({title: el.title, value: el.value}))
    order.recipeDetails.prismTableRowsRE = data.prismTableRowsRE.map((el: any) => ({title: el.title, value: el.value}))
    order.recipeDetails.prismTableRowsLE = data.prismTableRowsLE.map((el: any) => ({title: el.title, value: el.value}))
    order.recipeDetails.decentrationTableRowsRE = data.decentrationTableRowsRE.map((el: any) => ({title: el.title, value: el.value}))
    order.recipeDetails.decentrationTableRowsLE = data.decentrationTableRowsLE.map((el: any) => ({title: el.title, value: el.value}))
  },
  setFrameParameters({ order } : any, data: any) {
    order.frameParameters.frameParametersMiddle = data.frameParametersMiddle.map((el: any) => ({title: el.title, value: el.value}))
    order.frameParameters.frameParametersRowsRE = data.frameParametersRowsRE.map((el: any) => ({title: el.title, value: el.value}))
    order.frameParameters.frameParametersRowsLE = data.frameParametersRowsLE.map((el: any) => ({title: el.title, value: el.value}))
  },

  setMorphologicalParameters({ order } : any, data: any) {
    Vue.set(order.morphologicalParameters, data.key, data.value)
  },

  setActivity({ order }: any, data: object) {
    order.poll = {...order.poll, ...data};
  },

  setErrors(_state: any, data: any) {
    if(Object.keys(data).length) {
      _state.errors[data.key] = data.value
    } else {
      _state.errors = {}
    }
  },
  setDeliveryPoint(_state : any, id: string) {
    _state.selectedDeliveryPoint = id;
  },
  clearStateData(_state: any) {
    _state.order = {
      lens: {},
      baseValue: null,
      diameter: null,
      frameType: null,
      frameModel: '',
      productionType: null,
      repairType: [],
      paintingType: null,
      additionalType: [],
      frameColoring: '',
      UVProtect: false,
      comments: '',
      frameForm: {},
      arm: '',
      recipeDetails: {
        recipeDetailsTableRE: null,
        recipeDetailsTableLE: null,
        prismTableRowsRE: null,
        prismTableRowsLE: null,
        decentrationTableRowsRE: null,
        decentrationTableRowsLE: null,
      },
      frameParameters: {
        frameParametersMiddle: null,
        frameParametersRowsRE: null,
        frameParametersRowsLE: null,
      },
      morphologicalParameters: {
        clientGrowth: '',
        distanceToNear: '',
        insetOffsetR: '',
        insetOffsetL: '',
      },
      poll: {
        distantUse: '',
        averageUse: '',
        closeUse: '',
        recommendation: '',
      }
    }
    _state.orderRXFiltersChecked = {
      Type: '',
      Manufacturer: '',
      Style: '',
      Index: '',
      Design: '',
      Property: null,
      Tint: null,
      search_string: ''
    }

    _state.orderRXRules = null;
  },
};
