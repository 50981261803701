export default {
  tabsColoringLencor: ['Сплошное', 'Градиентное', 'Усиливающее контраст'],
  tabsColoringBBGR: ['Сплошное', 'Градиентное', 'Модные цвета'],
  tabsColorXperio: ['Сплошные', 'Градиентные'],
  tabsColorTransitions: ['Классические', 'Модные'],
  coloringLencor: {
    0: {
      header: ['85%', '75%', '50%', '25%'],
      color: [
        {
          title: 'Серый',
          items: [
            { id: 'LvGray85', img: 'solid_grey_85_lencorVision', percent: '85%' },
            { id: 'LvGray75', img: 'solid_grey_75_lencorVision', percent: '75%' },
            { id: 'LvGray50', img: 'solid_grey_50_lencorVision', percent: '50%' },
            { id: 'LvGray25', img: 'solid_grey_25_lencorVision', percent: '25%' },
          ]
        },
        {
          title: 'Коричневый',
          items: [
            { id: 'LvBrown85', img: 'solid_brown_85_lencorVision', percent: '85%' },
            { id: 'LvBrown75', img: 'solid_brown_75_lencorVision', percent: '75%' },
            { id: 'LvBrown50', img: 'solid_brown_50_lencorVision', percent: '50%' },
            { id: 'LvBrown25', img: 'solid_brown_25_lencorVision', percent: '25%' },
          ]
        },
        {
          title: 'Зеленый',
          items: [
            { id: 'LvGreen85', img: 'solid_green_85_lencorVision', percent: '85%' },
            { id: 'LvGreen75', img: 'solid_green_75_lencorVision', percent: '75%' },
            { id: 'LvGreen50', img: 'solid_green_50_lencorVision', percent: '50%' },
            { id: 'LvGreen25', img: 'solid_green_25_lencorVision', percent: '25%' },
          ]
        },
      ]
    },
    1: {
      header: ['85-0%', '75-0%', '50-0%'],
      color: [
        {
          title: 'Серый',
          items: [
            { id: 'LvDeGray8500', img: 'gradient_grey_85-0_lencorVision', percent: '85-0%' },
            { id: 'LvDeGray7500', img: 'gradient_grey_75-0_lencorVision', percent: '75-0%' },
            { id: 'LvDeGray5000', img: 'gradient_grey_50-0_lencorVision', percent: '50-0%' },
          ]
        },
        {
          title: 'Коричневый',
          items: [
            { id: 'LvDeBrown8500', img: 'gradient_brown_85-0_lencorVision', percent: '85-0%' },
            { id: 'LvDeBrown7500', img: 'gradient_brown_75-0_lencorVision', percent: '75-0%' },
            { id: 'LvDeBrown5000', img: 'gradient_brown_50-0_lencorVision', percent: '50-0%' },
          ]
        },
        {
          title: 'Зеленый',
          items: [
            { id: 'LvDeGreen8500', img: 'gradient_green_85-0_lencorVision', percent: '85-0%' },
            { id: 'LvDeGreen7500', img: 'gradient_green_75-0_lencorVision', percent: '75-0%' },
            { id: 'LvDeGreen5000', img: 'gradient _green_50-0_lencorVision', percent: '50-0%' },
          ]
        },
      ]
    },
    2: {
      header: ['85%', '65%', '40%'],
      color: [
        {
          title: 'Красно-коричневый',
          items: [
            { id: 'LvRedBrown85', img: 'contrast_red-brown_85_lencorVision', percent: '85%' },
            { id: 2, img: '', percent: '65%' },
            { id: 3, img: '', percent: '40%' },
          ]
        },
        {
          title: 'Желтый',
          items: [
            { id: 5, img: '', percent: '85%' },
            { id: 6, img: '', percent: '65%' },
            { id: 'LvYellow40', img: 'contrast_yellow_40_lencorVision', percent: '40%' },
          ]
        },
        {
          title: 'Оранжевый',
          items: [
            { id: 9, img: '', percent: '85%' },
            { id: 'LvOrange65', img: 'contrast_orange_65_lencorVision', percent: '65%' },
            { id: 'LvOrange40', img: 'contrast_orange_40_lencorVision', percent: '40%' },
          ]
        },
      ]
    }
  },
  coloringBBGR: {
    0: {
      header: ['95%', '85%', '62%', '35%', '18%'],
      color: [
        {
          title: 'BROWN',
          items: [
            { id: 'Brown95', img: 'solid_brown_95_bbgr', percent: '95%' },
            { id: 'Brown85', img: 'solid_brown_85_bbgr', percent: '85%' },
            { id: 'Brown62', img: 'solid_brown_62_bbgr', percent: '62%' },
            { id: 'Brown35', img: 'solid_brown_35_bbgr', percent: '35%' },
            { id: 'Brown18', img: 'solid_brown_18_bbgr', percent: '18%' },
          ]
        },
        {
          title: 'GREY',
          items: [
            { id: 'Grey95', img: 'solid_grey_95_bbgr', percent: '95%' },
            { id: 'Grey85', img: 'solid_grey_85_bbgr', percent: '85%' },
            { id: 'Grey62', img: 'solid_grey_62_bbgr', percent: '62%' },
            { id: 'Grey35', img: 'solid_grey_35_bbgr', percent: '35%' },
            { id: 'Grey18', img: 'solid_grey_18_bbgr', percent: '18%' },
          ]
        },
        {
          title: 'BLACK',
          items: [
            { id: 'Black95', img: 'solid_black_95_bbgr', percent: '95%' },
            { id: 'Black85', img: 'solid_black_85_bbgr', percent: '85%' },
            { id: 'Black62', img: 'solid_black_62_bbgr', percent: '62%' },
            { id: 'Black35', img: 'solid_black_35_bbgr', percent: '35%' },
            { id: 'Black18', img: 'solid_black_18_bbgr', percent: '18%' },
          ]
        },
        {
          title: 'GREY US',
          items: [
            { id: 'GreyUs95', img: 'solid_grey us_95_bbgr', percent: '95%' },
            { id: 'GreyUs85', img: 'solid_grey us_85_bbgr', percent: '85%' },
            { id: 'GreyUs62', img: 'solid_grey us_62_bbgr', percent: '62%' },
            { id: 'GreyUs35', img: 'solid_grey us_35_bbgr', percent: '35%' },
            { id: 'GreyUs18', img: 'solid_grey us_18_bbgr', percent: '18%' },
          ]
        },
        {
          title: 'BLUE INK',
          items: [
            { id: 'BlueInk95', img: 'solid_blue ink_95_bbgr', percent: '95%' },
            { id: 'BlueInk85', img: 'solid_blue ink_85_bbgr', percent: '85%' },
            { id: 'BlueInk62', img: 'solid_blue ink_62_bbgr', percent: '62%' },
            { id: 'BlueInk35', img: 'solid_blue ink_35_bbgr', percent: '35%' },
            { id: 'BlueInk18', img: '', percent: '18%' },
          ]
        },
        {
          title: 'BORDEAUX',
          items: [
            { id: 'Bordeaux95', img: 'solid_bordeaux_95_bbgr', percent: '95%' },
            { id: 'Bordeaux85', img: 'solid_bordeaux_85_bbgr', percent: '85%' },
            { id: 'Bordeaux62', img: 'solid_bordeaux_62_bbgr', percent: '62%' },
            { id: 'Bordeaux35', img: 'solid_bordeaux_35_bbgr', percent: '35%' },
            { id: 'Bordeaux18', img: '', percent: '18%' },
          ]
        },
        {
          title: 'KHAKI',
          items: [
            { id: 'Khaki95', img: 'solid_khaki_95_bbgr', percent: '95%' },
            { id: 'Khaki85', img: 'solid_khaki_85_bbgr', percent: '85%' },
            { id: 'Khaki62', img: 'solid_khaki_62_bbgr', percent: '62%' },
            { id: 'Khaki35', img: 'solid_khaki_35_bbgr', percent: '35%' },
            { id: 'Khaki18', img: '', percent: '18%' },
          ]
        },
        {
          title: 'BROWN US',
          items: [
            { id: 'BrownUs95', img: 'solid_brown us_95_bbgr', percent: '95%' },
            { id: 'BrownUs85', img: 'solid_brown us_85_bbgr', percent: '85%' },
            { id: 'BrownUs62', img: 'solid_brown us_62_bbgr', percent: '62%' },
            { id: 'BrownUs35', img: 'solid_brown us_35_bbgr', percent: '35%' },
            { id: 'BrownUs18', img: '', percent: '18%' },
          ]
        },
      ]
    },
    1: {
      header: ['Градиент'],
      color: [
        {
          title: 'BROWN ',
          items: [
            { id: 'DeBrown8500', img: 'gradient_brown_bbgr', percent: 'Градиент' },
          ]
        },
        {
          title: 'GREY',
          items: [
            { id: 'DeGrey8500', img: 'gradient_grey_bbgr', percent: 'Градиент' },
          ]
        },
        {
          title: 'BLACK',
          items: [
            { id: 'DeBlack8500', img: 'gradient_black_bbgr', percent: 'Градиент' },
          ]
        },
        {
          title: 'GREY US',
          items: [
            { id: 'DeGreyUs8500', img: 'gradient_grey us_bbgr', percent: 'Градиент' },
          ]
        },
        {
          title: 'BLUE INK',
          items: [
            { id: 'DeBlueInk8500', img: 'gradient_brlue ink_bbgr', percent: 'Градиент' },
          ]
        },
        {
          title: 'BORDEAUX',
          items: [
            { id: 'DeBordeaux8500', img: 'gradient_bordeaux_bbgr', percent: 'Градиент' },
          ]
        },
        {
          title: 'KHAKI',
          items: [
            { id: 'DeKhaki8500', img: 'gradient_khaki_bbgr', percent: 'Градиент' },
          ]
        },
        {
          title: 'BROWN US',
          items: [
            { id: 'DeBrownUs8500', img: 'gradient_brown us_bbgr', percent: 'Градиент' },
          ]
        },
      ]
    },
    2: {
      header: ['95%', '85%', '62%', '35%', '18%'],
      color: [
        {
          title: 'FRESH YELLOW',
          items: [
            { id: 'fsFreshYellow95', img: '', percent: '95%' },
            { id: 'fsFreshYellow85', img: 'fashion_fresh yellow_85_bbgr', percent: '85%' },
            { id: 'fsFreshYellow62', img: 'fashion_fresh yellow_62_bbgr', percent: '62%' },
            { id: 'fsFreshYellow35', img: 'fashion_fresh yellow_35_bbgr', percent: '35%' },
            { id: 'fsFreshYellow18', img: 'fashion_fresh yellow_18_bbgr', percent: '18%' },
          ]
        },
        {
          title: 'VIBRANT BLUE',
          items: [
            { id: 'fsVibrantBlue95', img: '', percent: '95%' },
            { id: 'fsVibrantBlue85', img: 'fashion_vibrant blue_85_bbgr', percent: '85%' },
            { id: 'fsVibrantBlue62', img: 'fashion_vibrant blue_62_bbgr', percent: '62%' },
            { id: 'fsVibrantBlue35', img: 'fashion_vibrant blue_35_bbgr', percent: '35%' },
            { id: 'fsVibrantBlue18', img: 'fashion_vibrant blue_18_bbgr', percent: '18%' },
          ]
        },
        {
          title: 'FLAME ROSE',
          items: [
            { id: 'fsFlameRose95', img: '', percent: '95%' },
            { id: 'fsFlameRose85', img: 'fashion_flame rose_85_bbgr', percent: '85%' },
            { id: 'fsFlameRose62', img: 'fashion_flame rose_62_bbgr', percent: '62%' },
            { id: 'fsFlameRose35', img: 'fashion_flame rose_35_bbgr', percent: '35%' },
            { id: 'fsFlameRose18', img: 'fashion_flame rose_18_bbgr', percent: '18%' },
          ]
        },
        {
          title: 'DEEP ORANGE',
          items: [
            { id: 'fsDeepOrange95', img: '', percent: '95%' },
            { id: 'fsDeepOrange85', img: 'fashion_deep orange_85_bbgr', percent: '85%' },
            { id: 'fsDeepOrange62', img: 'fashion_deep orange_62_bbgr', percent: '62%' },
            { id: 'fsDeepOrange35', img: 'fashion_deep orange_35_bbgr', percent: '35%' },
            { id: 'fsDeepOrange18', img: 'fashion_deep orange_18_bbgr', percent: '18%' },
          ]
        },
        {
          title: 'ABSOLUTE PURPLE',
          items: [
            { id: 'fsAbsolutePurple95', img: '', percent: '95%' },
            { id: 'fsAbsolutePurple85', img: 'fashion_absolute purple_85_bbgr', percent: '85%' },
            { id: 'fsAbsolutePurple62', img: 'fashion_absolute purple_62_bbgr', percent: '62%' },
            { id: 'fsAbsolutePurple35', img: 'fashion_absolute purple_35_bbgr', percent: '35%' },
            { id: 'fsAbsolutePurple18', img: 'fashion_absolute purple_18_bbgr', percent: '18%' },
          ]
        },
        {
          title: 'SOFT PURPLE',
          items: [
            { id: 'fsSoftPurple95', img: '', percent: '95%' },
            { id: 'fsSoftPurple85', img: 'fashion_soft purple_85_bbgr', percent: '85%' },
            { id: 'fsSoftPurple62', img: 'fashion_soft purple_62_bbgr', percent: '62%' },
            { id: 'fsSoftPurple35', img: 'fashion_soft purple_35_bbgr', percent: '35%' },
            { id: 'fsSoftPurple18', img: 'fashion_soft purple_18_bbgr', percent: '18%' },
          ]
        },
        {
          title: 'MAUVE (BEIGE PRALINE) ',
          items: [
            { id: 'fsMauve95', img: '', percent: '95%' },
            { id: 'fsMauve85', img: '', percent: '85%' },
            { id: 'fsMauve62', img: '', percent: '62%' },
            { id: 'fsMauve35', img: 'fashion_mauve (beige praline)_35_bbgr', percent: '35%' },
            { id: 'fsMauve18', img: '', percent: '18%' },
          ]
        },
        {
          title: 'PARMA (DELICATE BEIGE)',
          items: [
            { id: 'fsParma95', img: '', percent: '95%' },
            { id: 'fsParma85', img: '', percent: '85%' },
            { id: 'fsParma62', img: '', percent: '62%' },
            { id: 'fsParma35', img: 'fashion_parma (delicate beige)_35_bbgr', percent: '35%' },
            { id: 'fsParma18', img: '', percent: '18%' },
          ]
        },
        {
          title: 'BLUE (SECRET BLUE)',
          items: [
            { id: 'fsSecretBlue95', img: '', percent: '95%' },
            { id: 'fsSecretBlue85', img: '', percent: '85%' },
            { id: 'fsSecretBlue62', img: '', percent: '62%' },
            { id: 'fsSecretBlue35', img: 'fashion_blue (secret blue)_35_bbgr', percent: '35%' },
            { id: 'fsSecretBlue18', img: '', percent: '18%' },
          ]
        },
        {
          title: 'PINK (PINK POWDER)',
          items: [
            { id: 'fsPinkPowder95', img: '', percent: '95%' },
            { id: 'fsPinkPowder85', img: '', percent: '85%' },
            { id: 'fsPinkPowder62', img: '', percent: '62%' },
            { id: 'fsPinkPowder35', img: 'fashion_pink (pink powder)_35_bbgr', percent: '35%' },
            { id: 'fsPinkPowder18', img: '', percent: '18%' },
          ]
        },
      ]
    }
  },
  mirrorCoatingLencor: [
    { id: 'LvmGoldSparkle', title: 'Золотая искра', img: 'mirrored_Золотая искра_LencorVision' },
    { id: 'LvmSilverSparkle', title: 'Серебряная искра', img: 'mirrored_Серебряная искра_LencorVision' },
    { id: 'LvmBlueSparkle', title: 'Синяя искра', img: 'mirrored_Синяя искра_LencorVision' },
    { id: 'LvmYellowSilver', title: 'Желтое серебро', img: 'mirrored_Желтое серебро_LencorVision' },
    { id: 'LvmRose', title: 'Розовый', img: 'mirrored_Розовый_LencorVision' },
    { id: 'LvmYellow', title: 'Желтый', img: 'mirrored_Желтый_LencorVision' },
    { id: 'LvmBronze', title: 'Бронза', img: 'mirrored_Бронза_LencorVision' },
    { id: 'LvmGold', title: 'Золото', img: 'mirrored_Золото_LencorVision' },
    { id: 'LvmViolet', title: 'Фиолетовый', img: 'mirrored_Фиолетовый_LencorVision' },
    { id: 'LvmSapphire', title: 'Сапфир', img: 'mirrored_Сапфир_LencorVision' },
    { id: 'LvmGreen', title: 'Зеленый', img: 'mirrored_Зеленый_LencorVision' },
    { id: 'LvmFlameRed', title: 'Огненно-красный', img: 'mirrored_Огненно-красный_LencorVision' },
    { id: 'LvmSilver', title: 'Серебро', img: 'mirrored_Серебро_LencorVision' },
    { id: 'LvmPurple', title: 'Пурпурный', img: 'mirrored_Пурпурный_LencorVision' },
    { id: 'LvmAntracite', title: 'Антрацит', img: 'mirrored_Антрацит_LencorVision' },
  ],
  mirrorCoatingBBGR: [
    { id: 'mBronze', title: 'Бронзовый', img: 'mirrored_Бронзовый_bbgr' },
    { id: 'mOrange', title: 'Оранжевый', img: 'mirrored_Оранжевый_bbgr' },
    { id: 'mRoseGold', title: 'Розовое золото', img: 'mirrored_Розовое золото_bbgr' },
    { id: 'mKhaki', title: 'Хаки', img: 'mirrored_Хаки_bbgr' },
    { id: 'mLightBronze', title: 'Светло-бронзовый', img: 'mirrored_Светло-бронзовый_bbgr' },
    { id: 'mEmeraldGreen', title: 'Изумрудно-зеленый', img: 'mirrored_Изумрудно-зеленый_bbgr' },
    { id: 'mSilver', title: 'Серебристый', img: 'mirrored_Серебристый_bbgr' },
    { id: 'mAzureSapphire', title: 'Голубой сапфир', img: 'mirrored_Голубой сапфир_bbgr' },
    { id: 'mLightSilver', title: 'Светло-серебристый', img: 'mirrored_Светло-серебристый_bbgr' },
    { id: 'mRose', title: 'Розовый', img: 'mirrored_Розовый_bbgr' },
    { id: 'mViolet', title: 'Фиолетовый', img: 'mirrored_Фиолетовый_bbgr' },
    { id: 'mLightGreen', title: 'Светло-зеленый', img: 'mirrored_Светло-зеленый_bbgr' },
    { id: 'mAzuline', title: 'Лазурно-голубой', img: 'mirrored_Лазурно-голубой_bbgr' },
  ],
  colorXperio: {
    0: {
      header: [],
      color: [
        { title: 'Серый', items: [ {id: 'xGray', img: 'colour_solid_серый_xperio_bbgr'} ] },
        { title: 'Коричневый', items: [ {id: 'xBrown', img: 'colour_solid_коричневый_xperio_bbgr'} ] },
        { title: 'Серо-зеленый', items: [ {id: 'xGrayGreen', img: 'colour_solid_серо-зеленый_xperio_bbgr'} ] },
        { title: 'Сливовый', items: [ {id: 'xPlum', img: 'colour_solid_сливовый_xperio_bbgr'} ] },
        { title: 'Синий', items: [ {id: 'xBlue', img: 'colour_solid_синий_xperio_bbgr'} ] },
        { title: 'Янтарь', items: [ {id: 'xAmber', img: 'colour_solid_янтарь_xperio_bbgr'} ] },
        { title: 'Хаки', items: [ {id: 'xKhaki', img: 'colour_solid_хаки_xperio_bbgr'} ] },
      ]
    },
    1: {
      header: [],
      color: [
        { title: 'Серый', items: [ {id: 'xDeBrown', img: 'colour_gradient_серый_xperio_bbgr'} ] },
        { title: 'Коричневый', items: [ {id: 'xDeGray', img: 'colour_gradient_коричневый_xperio_bbgr'} ] },
        { title: 'Серо-зеленый', items: [ {id: 'xDeGrayGreen', img: 'colour_gradient_серо-зеленый_xperio'} ] },
        { title: 'Сливовый', items: [ {id: 'xDePlum', img: 'colour_gradient_сливовый_xperio_bbgr'} ] },
        { title: 'Синий', items: [ {id: 'xDeBlue', img: 'colour_gradient_синий_xperio_bbgr'} ] },
        { title: 'Янтарь', items: [ {id: 'xDeAmber', img: 'colour_gradient_янтарь_xperio_bbgr'} ] },
        { title: 'Хаки', items: [ {id: 'xDeKhaki', img: 'colour_gradient_хаки градиент_xperio_bbgr'} ] },
      ]
    }
  },
  colorTransitions: {
    0: {
      header: [],
      color: [
        { title: 'Серый', items: [ {id: 'Tr8Gray', img: 'colour_ classical_серый_transitions_bbgr_lencorVision'} ] },
        { title: 'Коричневый', items: [ {id: 'Tr8Brown', img: 'colour_ classical_коричневый_transitions'} ] },
        { title: 'Серо-зеленый', items: [ {id: 'Tr8GrayGreen', img: 'colour_ classical_серо-зеленый_transitions_bbgr_lencorVision'} ] },
      ]
    },
    1: {
      header: [],
      color: [
        { title: 'Изумруд', items: [ {id: 'TrFsEmerald', img: 'colour_fashion_изумруд_transitions_bbgr_lencorVision'} ] },
        { title: 'Аметист', items: [ {id: 'TrFsSappfire', img: 'colour_fashion_аметист_transitions_bbgr_lencorVision'} ] },
        { title: 'Сапфир', items: [ {id: 'TrFsAmethyst', img: 'colour_fashion_сапфир_transitions_bbgr_lencorVision'} ] },
        { title: 'Янтарь', items: [ {id: 'TrFsAmber', img: 'colour_fashion_янтарь_transitions_bbgr_lencorVision'} ] },
      ]
    }
  },
  colorTransitionsXTRActive: {
    0: {
      header: [],
      color: [
        { title: 'Серый', items: [ {id: 'TrXtGray', img: 'colour_серый_transitions_XTRActive_bbgr'} ] },
        { title: 'Коричневый', items: [ {id: 'TrXtBrown', img: 'colour_коричневый_transitions_XTRActive_bbgr'} ] },
        { title: 'Серо-зеленый', items: [ {id: 'TrXtGrayGreen', img: 'colour_серо-зеленый_transitions_XTRActive_bbgr'} ] },
      ]
    }
  },
  colorSunsensy: {
    0: {
      header: [],
      color: [
        { title: 'Серый', items: [ {id: 'LvSunSGray', img: 'colour_серый_Sunsensy_LencorVision'} ] },
        { title: 'Коричневый', items: [ {id: 'LvSunSBrown', img: 'colour_коричневый_Sunsensy_LencorVision'} ] },
      ]
    }
  }
}
