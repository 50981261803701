export default {
  setNomenclatureEmptyLenses(_state: any, data: any) {
    _state.nomenclatureEmptyLenses = data;
  },
  setNomenclatureExtendedFiltersChecked(_state: any, data: any) {
    _state.nomenclatureExtendedFiltersChecked[data.label] = data.value;
  },
  setNomenclatureExtendedFilters(_state: any, data: any) {
    _state.nomenclatureExtendedFilters = data;
  },
  setSegments(_state: any, data: any) {
    _state.segments = data;
  },
  setCurrentLens(_state: any, data: any) {
    _state.lens = data;
  },
  setDeliveryPointSelected(_state: any, data: any) {
    _state.deliveryPointSelected = data;
  }
};
