export default {
  setValue(_state: any, obj: any) {
    const key = Object.keys(obj)[0];
    _state[key] = obj[key];
  },
  setDeliveryPoints(_state: any, data: any) {
    _state.deliveryPoints = data;
  },
  setOwnCurrentPath(_state: any, data: any) {
    _state.ownCurrentPath = data;
  },

  setNotifications(_state: any, data: any) {
    _state.notifications = data;
  },
  setNotificationsMeta(_state: any, data: any) {
    _state.notificationsMeta = data;
  },
  deleteNotifications(_state: any, id: string) {
    _state.notifications = _state.notifications.filter((el: any) => el.id !== id);
  },
};
