import api from "@/api/historyOrders";

export default {
  async getHistoryOrders({commit}: any, data: any) {
    try {
      const res: any = await api.getHistoryOrders(data);
      return res.data || null;
    } catch (error) {
      return {error: error.response.data};
    }
  },
  async getHistoryOrdersRX({commit}: any, data: any) {
    try {
      const res: any = await api.getHistoryOrdersRX(data);
      return res.data || null;
    } catch (error) {
      return {error: error.response.data};
    }
  },
};
