import api from "@/api/auth";
import userApi from "@/api/employees";
import router from '@/router'

import { Credentials } from "@/views/Auth/models/auth";
import { localStorageService } from "@/services/localStorage";

export default {
  login({ commit }: any, data: Credentials) {
    return new Promise((resolve, reject) => {
      api.loginUser(data).then((res: any) => {
        if (res.access_token) {
          localStorageService.save({ key: 'AUTH_TOKEN', value: res.access_token });
          localStorageService.save({ key: 'REFRESH_TOKEN', value: res.refresh_token });
          resolve('ok');
          commit('authSuccess', res);
          commit('authSuccessCopy', res);
          router.push('/');
        } else if (res.error) {
          commit('setError', true);
          commit('setErrorText', res.error);
        }
      }).catch((error: any) => {
        if (error.response.status === 400) {
          const errorText = 'Вы ввели неверный логин или пароль';
          commit('setErrorText', errorText);
        }
        commit('setError', true);
        reject(error);
      });
    });
  },

  create({ commit }: any, data: string) {
    return new Promise((resolve, reject) => {
      api
        .restorePassword(data)
        .then(resolve)
        .catch((e: any) => {
          commit("setError", true);
          reject(e);
        });
    });
  },

  async updateUser({ commit }: any, data: string) {
    try {
      const res = await userApi.updateEmployee(data);
      await commit('completeUser', res)
    } catch (error) {
      // console.log(error);
    }
  },
  restore({ commit }: any, email: string) {
    return new Promise((resolve, reject) => {
      api
        .resetPassword(email)
        .then(resolve)
        .catch((e: any) => {
          commit("setError", e);
          reject(e);
        });
    });
  },
  logout({ commit }: any) {
    commit("logout");
  },
  async getUser({ _state }: any, data: any) {
    try {
      const user = await api.getUser(data).then((res: any) => res.collection[0]);
      return user
    } catch (error) {
      return error
    }
  },
  async sendMessage({ commit }: any, data: any) {
    try {
      await api.sendMessage(data);
      return { error: false }
    } catch (error) {
      return { error: true }
    }
  },
  async changeCurrentAccount({ commit }: any, data: any) {
    try {
      const res: any = await api.changeCurrentAccount(data);
      localStorageService.save({ key: 'AUTH_TOKEN', value: res.access_token });
      commit("setChangedAccount", true);
      return res
    } catch (error) {
      return 'error'
    }
  },
};
