import axios from "axios";

const BASE_VUE_APP_URL = process.env.VUE_APP_URL;

const cart = axios.create({
  timeout: 30000,
  baseURL: BASE_VUE_APP_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  maxContentLength: 10000,
});


const GET_HISTORY_ORDER = `${BASE_VUE_APP_URL}/order/api/v1/cart/get_orders_history/?page=`;
const GET_HISTORY_ORDER_RX = `${BASE_VUE_APP_URL}/order/api/v1/cart/rx/get_orders_history/?page=`;

export default {
  getHistoryOrders(data: any) {
    return cart.post(`${GET_HISTORY_ORDER}${data.page || 1}`,{...data.values}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('AUTH_TOKEN')}`,
      },
    })
  },
  getHistoryOrdersRX(data: any) {
    return cart.post(`${GET_HISTORY_ORDER_RX}${data.page || 1}`,{...data.values}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('AUTH_TOKEN')}`,
      },
    })
  },
}
