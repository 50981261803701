import client from '@/api/client';

const BASE_API = 'api/v1';

const EMPLOYEES_ADD_USERS_XLSX = `${BASE_API}/users/add_users/`;
const EMPLOYEES_GET_USERS = `${BASE_API}/users/`;
const EMPLOYEES_ADD_NEW_EMPLOYEE = `${BASE_API}/users/create_user/`;
const EMPLOYEES_UPDATE_EMPLOYEE = `${BASE_API}/users/update_user/`;

const GET_ROLES = `${BASE_API}/roles/`;
const GET_USER_SIMPLEFIED = `/api/v1/users_simplefied/`;
const CHANGE_ROLE = `${BASE_API}/users/change_role/`;
const CREATE_ROLE = `${BASE_API}/roles/create_role/`;
const UPDATE_ROLE = `${BASE_API}/roles/update_role/`;

const GET_META_SUBDIVISIONS = `${BASE_API}/subdivisions/`;
const SAVE_SUBDIVISIONS = `${BASE_API}/subdivisions/`;
const UPDATE_SUBDIVISIONS = `${BASE_API}/subdivisions/update_subdivision/`;

const SEND_INVITATION = `${BASE_API}/authorization/send_email_to_activate/`

export default {
    addUsersXLSX(data: any) {
        return client.post(EMPLOYEES_ADD_USERS_XLSX, data);
    },
    getUsersList(params?: any): Promise<any> {
        return client.get(EMPLOYEES_GET_USERS, { params });
    },
    getUser(id?: any): Promise<any> {
        return client.get(`${EMPLOYEES_GET_USERS}${id}/`); // slash in the end is feature
    },
    addNewEmployee(data: any) {
        return client.post(EMPLOYEES_ADD_NEW_EMPLOYEE, data);
    },
    updateEmployee(data: any) {
        return client.post(EMPLOYEES_UPDATE_EMPLOYEE, data);
    },

    getRoles(params?: any) {
        return client.get(GET_ROLES, { params });
    },
    postRole(data: any) {
        return client.post(CREATE_ROLE, data);
    },
    patchRole(data: any) {
        return client.post(UPDATE_ROLE, data);
    },
    changeRole(data: object) {
        return client.post(CHANGE_ROLE, data);
    },

    getDepartmentsList(params?: any) {
        return client.get(GET_META_SUBDIVISIONS, { params });
    },
    postDepartment(dept: { name: string; leader: string }) {
        return client.post(SAVE_SUBDIVISIONS, dept);
    },
    patchDepartment(dept: { name: string; leader: string, id?: number|string }) {
        return client.post(UPDATE_SUBDIVISIONS, dept);
    },

    sendInvitation(list: any[]) {
        return client.post(SEND_INVITATION, list);
    },
    getUsersSimplified() {
      return client.get(GET_USER_SIMPLEFIED);
    },
}
