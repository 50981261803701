import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

import auth from "./modules/auth/index"
import clients from "./modules/clients/index"
import employees from "./modules/employees/index"
import catalog from "./modules/catalog/index"
import client from "./modules/client/index"
import cart from "./modules/cart/index"
import orderRX from "./modules/orderRX/index"
import historyOrders from "./modules/historyOrders/index"
import marketing from "./modules/marketing/index"
import financialInformation from "./modules/financialInforamation/index";

import state from "./state";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    auth,
    client,
    clients,
    employees,
    catalog,
    cart,
    orderRX,
    historyOrders,
    marketing,
    financialInformation
  },
  state,
  mutations,
  actions,
  getters,
  strict: debug,
  plugins: [createPersistedState({paths: ['auth']})],
});
