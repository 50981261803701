import client from '@/api/client';

const GET_DELIVERY_POINTS = `api/v1/orders/delivery_points/`

const GET_NOTIFICATIONS = `v1/notifications`
const GET_NOTIFICATIONS_META = `v1/notifications/meta`
const GET_NOTIFICATION_BY_USER = `v1/notifications/user/`
const CREATE_NOTIFICATION = `v1/notifications/create`
const READ_NOTIFICATIONS = `v1/notifications/read`
const DELETE_NOTIFICATION = `v1/notifications/delete/`

export default {
  getDeliveryPoints(params: any) {
    return client.get(GET_DELIVERY_POINTS, { params });
  },

  getNotifications() {
    return client.get(GET_NOTIFICATIONS);
  },
  getNotificationsByUser(id: string) {
    return client.get(`${GET_NOTIFICATION_BY_USER}${id}`);
  },
  getNotificationsMeta() {
    return client.get(GET_NOTIFICATIONS_META);
  },
  createNotification(params: any) {
    return client.post(CREATE_NOTIFICATION, { ...params });
  },
  readNotification(params: any) {
    return client.post(READ_NOTIFICATIONS, { ...params });
  },
  deleteNotification(id: string) {
    return client.delete(`${DELETE_NOTIFICATION}${id}`);
  },
}
