import Vue from "vue";

const initialPromotionState = {
  data: {
    name: '',
    description: '',
    is_active: false,
  },
  materials: []
};

export default {
  setPromotions(state: any, promotions: any) {
    state.promotions = promotions;
  },
  setValue(state: any, obj: any) {
    const key = Object.keys(obj)[0];
    state[key] = obj[key];
  },
  // setErrors(state: any, data: any) {
  //   console.log(data);
  //   state.errors = data
  // },
  setPromotion(state: any, promotion: any) {
    state.promotion = promotion;
  },
  resetPromotionState(state: object) {
    Object.assign(state, initialPromotionState)
  },
  setPromoCodes(state: any, promoCodes: any) {
    state.promoCodes = promoCodes;
  },
  setSelectedPromoCodes(state: any, data: any) {
    data.promoCode.orderNo = data.orderNo
    state.selectedPromoCodes = [...state.selectedPromoCodes, data.promoCode];
  },
  removePromoCode(state: any, promoCodeId: any) {
    const index = state.selectedPromoCodes.findIndex((item: { id: string; }) => item.id === promoCodeId);

    if (index > -1) {
      state.selectedPromoCodes.splice(index, 1);
    }
  },
};
