import {OrderRXFilters, OrderRXRules} from './types';

export default {
  triggerPage: 'lens',
  manufacturer: 'lencor',
  selectedDeliveryPoint: '',
  orderRXLenses: [],
  orderRXFilters: {} as OrderRXFilters,
  orderRXRules: {} as OrderRXRules,
  chosenLens: null,
  orderRXFiltersChecked: {
    Type: '',
    Manufacturer: '',
    Style: '',
    Index: '',
    Design: '',
    Property: null,
    Tint: null,
    search_string: ''
  },
  lensParameters: {
    color: '',
    coloring: '',
    mirrorCoating: '',
  },
  order: {
    lens: {},
    baseValue: null,
    diameter: null,
    frameType: null,
    frameModel: '',
    productionType: null,
    repairType: [],
    paintingType: null,
    additionalType: [],
    frameColoring: '',
    UVProtect: false,
    comments: '',
    frameForm: {},
    arm: '',
    recipeDetails: {
      recipeDetailsTableRE: null,
      recipeDetailsTableLE: null,
      prismTableRowsRE: null,
      prismTableRowsLE: null,
      decentrationTableRowsRE: null,
      decentrationTableRowsLE: null,
    },
    frameParameters: {
      frameParametersMiddle: null,
      frameParametersRowsRE: null,
      frameParametersRowsLE: null,
    },
    morphologicalParameters: {
      clientGrowth: '',
      distanceToNear: '',
      insetOffsetR: '',
      insetOffsetL: '',
    },
    poll: {
      distantUse: '',
      averageUse: '',
      closeUse: '',
      recommendation: '',
    }
  },
  errors: {},
  frameTypes: [
    { label: 'Ободковая (0.8 мм)', value: '1' },
    { label: 'На леске (1.8 мм)', value: '2' },
    { label: 'Безободковая (2.00 мм)', value: '3' },
    { label: 'Точное значение', value: '4' },
  ],
  productionOptions: [
    { label: 'Ободок (пластик)', value: '1' },
    { label: 'Ободок (металл)', value: '2' },
    { label: 'Леска (полуободковая)', value: '3' },
    { label: 'Винты (безободковая)', value: '4' },
  ],
  repairOptions: [
    { label: 'Замена втулок', value: '1' },
    { label: 'Выправка оправы', value: '2' },
    { label: 'Замена винтов', value: '3' },
    { label: 'Замена лески', value: '4' },
    { label: 'Замена наконечников', value: '5' },
    { label: 'Замена носоупоров', value: '6' },
    { label: 'Чистка оправы', value: '7' },
  ],
  additionalOptions: [
    { label: 'Полировка торца линзы', value: '1' },
    { label: 'Срочное изготовление', value: '2' },
  ],
};
