import client from '@/api/client';
import { Credentials } from "@/views/Auth/models/auth";

const BASE_API = 'api/v1';

const LOGIN = `${BASE_API}/authorization/`;
const RESTORE = `${BASE_API}/authorization/reset_pass/`;
const RESET = `${BASE_API}/authorization/reset_pass_email/`;
// const SEND_INVITATION = `${BASE_API}/authorization/send_email_to_activate/`;
// const LOGOUT = `${BASE_API}/authorization/logout/`;
const GET_USER = `${BASE_API}/users`;
const SEND_FEEDBACK = `${BASE_API}/users/send_feedback/`;
const CHANGE_CURRENT_ACCOUNT = `${BASE_API}/authorization/authorization_to_user/`;

export default {
  loginUser(data: Credentials) {
    return client.post(LOGIN, data);
  },
  restorePassword(data: any) {
    return client.post(RESTORE, data);
  },
  resetPassword(email: any) {
    return client.post(RESET, email);
  },
  getUser(data: any) {
    return client.get(`${GET_USER}/${data}/`);
  },
  sendMessage(message: any) {
    return client.post(SEND_FEEDBACK, message);
  },
  changeCurrentAccount(data: object) {
    return client.post(CHANGE_CURRENT_ACCOUNT, data);
  },
}
