import api from "@/api/marketing";

export default {
  async getPromotions({ commit }: any, clientId: string) {
    const result = await api.getPromotions(clientId);

    commit('setPromotions', result);
    return result;
  },
  async getPromotion({ commit }: any, data: any) {
    const result = await api.getPromotion(data);

    commit('setPromotion', result);
    return result;
  },
  async resetPromotionState({ commit }: any) {
    commit('resetPromotionState');
  },
  async acceptOffer({ commit }: any, data: object) {
    const result = await api.acceptOffer(data);

    return result;
  },
  async generateMaterialPdf({ commit }: any, materialId: string) {
    const result = await api.generateMaterialPdf(materialId);

    return result;
  },
  // Promocodes
  async getPromoCodes({ commit }: any, id: string) {
    const result = await api.getPromoCodes(id);

    commit('setPromoCodes', result);
    return result;
  },
  selectPromoCode({ commit }: any, data: object) {
    commit('setSelectedPromoCodes', data);
  },
  cancelPromoCode({ commit }: any, promoCodeId: string) {
    commit('removePromoCode', promoCodeId);
  },
};
