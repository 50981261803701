import Login from '@/views/Auth/components/Login.vue';
import Register from '@/views/Auth/components/Register.vue';
import Restore from '@/views/Auth/components/Restore.vue';
import Agreement from '@/views/Auth/components/Agreement.vue';

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: 'Вход',
      layout: 'auth',
    },
  },
  {
    path: "/recover/:id",
    name: "Register",
    component: Register,
    meta: {
      title: 'Создание нового пароля',
      layout: 'auth',
    },
  },
  {
    path: '/reset',
    name: 'Restore',
    component: Restore,
    meta: {
      title: 'Сброс пароля',
      layout: 'auth',
    },
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: Agreement,
    meta: {
      title: 'Пользовательское соглашение',
      layout: 'auth',
    },
  },
];

export default routes;
