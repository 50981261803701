









































import Vue from "vue";
import {
  mapState,
  mapMutations,
} from "vuex";

import Button from "@/components/ui/Button.vue";
import BackToLogin from "@/views/Auth/components/BackToLogin.vue";

export default Vue.extend({
  name: "RestorePasswordForm",

  components: {
    Button,
    BackToLogin,
  },

  data() {
    return {
      form: this.$form.createForm(this, { name: 'restore-password' }),
    };
  },

  computed: {
    ...mapState('auth', ['error'])
  },

  mounted() {
    this.setError('');
  },

  methods: {
    ...mapMutations("auth", [ "setError"]),

    handleSubmit(e: any) {
      e.preventDefault();

      this.$emit('restore', {email: this.form.getFieldValue('email')});
    },

    emailChange(e: any) {
      this.form.setFieldsValue({
        email: e.target.value
      });
      this.setError('');
    },

    hasErrors(fieldsError: any) {
      return Object.keys(fieldsError).some(field => fieldsError[field]);
    }
  },
});
