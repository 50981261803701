class LocalStorage {
  save(data: Record<string, string>) {
    return localStorage.setItem(data.key, data.value);
  }

  load(item: string) {
    return localStorage.getItem(item);
  }

  remove(item: string) {
    return localStorage.removeItem(item);
  }
}
export const localStorageService = new LocalStorage();
