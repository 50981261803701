// import { storage } from '@/services/storage';
import { localStorageService } from '@/services/localStorage';

const requestSignature = (config: any) => {
  // const access = storage.load('@/access');
  const access = localStorageService.load('AUTH_TOKEN');
  // console.log(access);
  if (!!access) {
    config.headers.Authorization = `Bearer ${access}`;
  } else {
    delete config.headers.Authorization;
  }
  return config;
};

const expirationCheck = (store: any) => async (response: any) => {
  const { status } = response;
  // console.log('response: ', response);
  if (status === 401) {
    await store.dispatch('auth/refresh');
    // return client(error.config);
    return Promise.reject(new Error(response.statusText));
  }
  if (status === 400) {
    await store.commit('auth/setError');
    return Promise.reject(new Error(response.statusText));
  }
  return response.data;
};

function init(store: any, client: any) {
  client.interceptors.request.use(requestSignature);
  client.interceptors.response.use(expirationCheck(store));
}

export default init;
