import { getAvatarLink } from '@/utils/url';

export default {
    getClients: (_state: any) => _state.clients
        .map((el: any) => ({
            key: el.id,
            name: el.name,
            status: el.is_active ? 'true' : 'false',
        }
    )),
    getClientsCollection: (_state: any) => _state.clientsCollection.data,
    getManagersCollection: (_state: any) => _state.managersCollection.data,
    getPointsCollection: (_state: any) => _state.pointsCollection,
    getEmployees: (_state: any) => _state.employees.data
        .map((el: any) => ({
            ...el.user_data,
            status: el.is_active ? 'true' : 'false',
            img: getAvatarLink(el.user_data.userpic_url) || "",
            fio: `${el.user_data.lastname} ${el.user_data.name} ${el.user_data.middlename}`
        }
    )),
    getDeliveryPoints: (state: any) => state.deliveryPoints.data
      .map((el: any) => ({...el, status: !!el.is_active})),
    getEmployeesPagination: (_state: any) => ({
      total: _state.employees.meta.number_of_records,
      current: _state.employees.meta.page,
      pageSize: _state.employees.meta.records_on_page,
    }),
    getPointsPagination: (state: any) => ({
      total: state.deliveryPoints.meta.number_of_records,
      current: state.deliveryPoints.meta.page,
      pageSize: state.deliveryPoints.meta.records_on_page,
    })
};
