import { ActionContext } from 'vuex';

import api from "@/api/clients";
import metaApi from '@/api/meta';

import { localStorageService } from "@/services/localStorage";

export default {
  async addClientsXLSX({ commit }: any, data: object) {
    try {
      await api.addClientsXLSX(data);
    } catch (error) {
      // console.log('ошибка в загрузке файла XLSX');
    }
  },
  async createClient({ commit }: any, data: object) {
    try {
      const res: any = await api.createClient(data);
      return res;
    } catch (error) {
      return { error: error.response.data };
    }
  },
  async updateClient({ commit }: any, data: object) {
    try {
      const res: any = await api.updateClient(data);
      return res;
    } catch (error) {
      return { error: error.response.data };
    }
  },
  async getClient({ commit }: any, data: object) {
    try {
      const res: any = await api.getClient(data);
      commit("setClient", res);
    } catch (error) {
      // console.log(error);
    }
  },
  async getClients({ commit }: any, data: object) {
    try {
      const res: any = await api.getClientsList(data);
      commit("setClients", res.data);
      commit("setClientsPagination", res.meta);
    } catch (error) {
      // console.log(error);
    }
  },
  async changeRole({ commit }: any, { users, role }: any) {
    try {
      await api.changeRole({ users, role });
    } catch (error) {
      // console.log(error);
    }
  },
  async getClientEmployeesList({ commit }: any, params: object) {
    try {
      const res: any = await api.getClientEmployeesList(params);
      commit("setValue", { employees: res });
    } catch (error) {
      // console.log(error);
    }
  },
  async getDeliveryPoints({ commit }: any, params: object) {
    try {
      const res: any = await api.getDeliveryPointsList(params);
      commit("setValue", { deliveryPoints: res });
    } catch (error) {
      // console.log(error);
    }
  },
  async saveClientsEmployee(context: ActionContext<any, any>, data: any): Promise<any> {
    try {
      let res;
      if (data.id) {
        res = await api.patchClientsEmployee(data);
        return res;
      }
      res = await api.postClientsEmployee(data);
      return res;
    } catch (error) {
      // console.log(error);
      return { error: error.response.data };
    }
  },
  async saveDeliveryPoint(context: any, data: any) {
    try {
      let res;
      if (data.id) {
        res = await api.patchDeliveryPoint(data); // БЫЛ НЕПРАВИЛЬНЫЙ ЗАПРОС СМОТРЕТЬ В API/CLIENTS
      } else {
        res = await api.postDeliveryPoint(data); // БЫЛ НЕПРАВИЛЬНЫЙ ЗАПРОС СМОТРЕТЬ В API/CLIENTS
      }
      return res;
    } catch (error) {
      return { error: error.response.data };
    }
  },
  async getClientsList({ commit }: any) {
    try {
      const res: any = await metaApi.getClients();
      commit("setValue", { clientsCollection: res });
    } catch (error) {
      // console.log(error);
    }
  },
  async getManagersList({ commit }: any) {
    try {
      const res: any = await metaApi.getManagersList();
      commit("setValue", { managersCollection: res });
    } catch (error) {
      // console.log(error);
    }
  },
  async getDeliveryPointsList({ commit }: any) {
    try {
      const res: any = await metaApi.getDeliveryPointsList();
      commit("setValue", { pointsCollection: res });
    } catch (error) {
      // console.log(error);
    }
  },
  async getLeaders({ commit }: any, id: string) {
    try {
      const res: any = await api.getLeaders(id);
      commit("setLeaders", res);
    } catch (error) {
      // console.log(error);
    }
  },
};
