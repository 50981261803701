<template lang="pug">
.Header
  .Header__Logo(@click="$router.push('/')")
    img(alt="Logo", src="../assets/img/Logo_MOK.webp")
  .Header__Actions(v-if="!loading")
    a-dropdown.Header__Action.Header__Action--Disable(
      placement="bottomRight",
      :trigger="['click']",
      v-model="visible"
    )
      a.ant-dropdown-link.notification-block(
        @click="(e) => e.preventDefault()"
      )
        a-badge.notification__count(
          v-if="notifications.length",
          status="processing"
        )
        img.Header__ActionIcon(
          alt="Notification",
          src="../assets/img/bell.png"
        )
      a-menu(
        slot="overlay",
        v-if="notifications.length",
        @click="handleMenuClick"
      )
        a-menu-item.notification-item(
          key="1",
          v-for="el in notifications",
          :key="el.id"
        )
          .left
            p.notification-item__title {{ el.text }}
            .notification-link(v-if="el.url")
              p Ссылка:
              a(:href="el.url", target="_blank") {{ el.url }}
          .right
            a-icon.notification__icon(type="close", @click="read(el.id)")
    .Header__Action.Header__Action--Cart(
      @click="$router.push('/cart')",
      v-if="checkAvailability('acc_order_wrh_les')"
    )
      .Cart
        a-badge.Cart__Count(
          v-if="getitemsCount",
          :count="getitemsCount",
          :overflow-count="999"
        )
        img.Header__ActionIcon(alt="Cart", src="../assets/img/cart.png")

    a-dropdown.User(placement="bottomRight", :trigger="['hover']")
      a.ant-dropdown-link(@click="(e) => e.preventDefault()")
        img.User__Avatar(:src="userImg", v-if="userImg", alt="")
        .User__Avatar.User__Avatar--Default(v-else)
          svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104" <circle cx="52" cy="52" r="52" fill="#EDEEF4"/><ellipse cx="51.999" cy="72.805" rx="31.2" ry="18.2" fill="#fff"/><circle cx="51.999" cy="31.206" r="15.6" fill="#fff"/>
        .User__Name {{ userName }}
      a-menu.User__Menu(slot="overlay")
        a-menu-item.menu-item(key="1")
          a-icon(type="setting")
          router-link(to="/user-settings") Настройки
        a-menu-divider
        a-menu-item(key="2", @click="logOut")
          a-icon(type="logout")
          span.logout(style="cursor: pointer") Выход
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { localStorageService } from "@/services/localStorage";
import { getAvatarLink } from "../utils/url"

export default Vue.extend({
  name: "Header",

  data () {
    return {
      loading: true,
      visible: false,
      notifications: [],
      readNotif: [],
    }
  },

  watch: {
    currentOrder () {
      if (this.orders) {
        this.setItemsCountInCart()
      }
    },
    getOrdersLength () {
      if (this.orders) {
        this.setItemsCountInCart()
      }
    },
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("cart", ["itemsCount", "orders", "currentOrder"]),
    ...mapGetters('auth', { permissions: 'getPermissions' }),
    userName () {
      if (this.user.client) {
        return this.user && this.user.name ? `${this.user.lastname || ''} ${this.user.name || ''} (${this.user.client || ''})` : 'User';
      }
      return this.user && this.user.name ? `${this.user.lastname || ''} ${this.user.name || ''}` : 'User';
    },
    userImg () {
      return getAvatarLink(this.user.user_pic) || '';
    },
    getitemsCount () {
      let value1 = 0;
      let value2 = 0;
      for (const key in this.orders.data) {
        value1 += 1;
        this.orders.data[key].items.forEach(el => {
          value2 += el.count
        })
      }
      if (value1 === 0) return ''
      else if (value1 === 1) return value2
      else return `${value1}/${value2}`
    }
  },

  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("cart", ["getOrders"]),
    ...mapMutations("cart", ["setCurrentOrder", "setItemsCountInCart"]),
    ...mapMutations("auth", ["setChangedAccount"]),
    ...mapActions(['getNotificationsByUser', 'readNotification']),

    logOut () {
      this.setChangedAccount(false)
      localStorageService.remove("AUTH_TOKEN");
      localStorageService.remove("REFRESH_TOKEN");
      this.logout();
      this.$router.push("/login");
    },
    checkAvailability (field) {
      if (!this.permissions) {
        return false;
      }
      const numericalField = ['client_adm', 'warehouse_vis', 'mrk_prm', 'feedback', 'profile_adm'];
      if (numericalField.includes(field)) {
        return Boolean(this.permissions[field]);
      }
      return this.permissions[field];
    },
    read (id) {
      this.readNotif.push(id)
      this.notifications = this.notifications.filter(el => el.id !== id)
      setTimeout(() => this.readActiveNotification(), 5000)
    },
    async readActiveNotification () {
      if (this.notifications.length) {
        const notifications = this.notifications.map(el => el.id)
        await this.readNotification({ user: this.user.id, notifications: this.readNotif })
        this.notifications = await this.getNotificationsByUser(this.user.id)
        this.readNotif = []
      }
    },
    handleMenuClick () {
      this.visible = true;
    }
  },

  async mounted () {
    if (this.user && this.user.guid_1c) {
      await this.getOrders({ client_guid: this.user.guid_1c });
      await this.setCurrentOrder(Object.keys(this.orders.data)[0]);
      this.notifications = await this.getNotificationsByUser(this.user.id);
    }

    this.loading = false
  }
});
</script>

<style lang="scss" scoped>
.Header {
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 64px;
  padding: 0 24px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
}

.Header__Logo {
  cursor: pointer;
  img {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 1200px) {
    margin-left: 30px;
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
}

.Header__Actions {
  display: flex;
  align-self: stretch;
  margin-left: auto;
}

.Header__Action {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding: 0 12px;
  transition: 0.15s ease-in-out;

  &:not(.Header__Action--Disable):hover {
    background-color: #f6f6f6;
    cursor: pointer;
  }
}

.Header__ActionIcon {
  width: 24px;
  height: 24px;
}

.User {
  display: inline-flex;
  align-items: center;
  padding-right: 11px;
  padding-left: 5.5px;
  margin-right: -11px;

  @media screen and (max-width: 768px) {
    padding-left: 11px;
  }

  &.ant-dropdown-open,
  &:hover {
    background-color: #f6f6f6;
  }
}

.User__Avatar {
  border-radius: 50%;
  object-fit: cover;
  object-position: center center;
  width: 24px;
  height: 24px;
  margin-right: 8px;

  @media screen and (max-width: 768px) {
    margin-right: 0;
  }

  svg {
    max-width: 100px;
    max-height: 100%;

    circle:first-child {
      fill: var(--blue-gray);
    }
  }
}

.User__Name {
  color: var(--body);

  @media screen and (max-width: 992px) {
    max-width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 768px) {
    max-width: 170px;
  }

  @media screen and (max-width: 576px) {
    display: none;
  }
}

.User__Menu {
  max-width: 150px;

  @media screen and (max-width: 576px) {
    max-width: 100%;
  }

  .menu-item {
    display: flex;
    align-items: center;
  }

  .ant-dropdown-menu-item > .anticon:first-child {
    min-width: 14px;
    margin-right: 8px;
    font-size: 14px;
  }
}

.Cart {
  align-self: center;
  cursor: pointer;
  padding: 5px;
  margin-right: 12px;
  position: relative;
}

.Cart__Count {
  position: absolute;
  top: -8px;
  right: -12px;
}
.notification-block {
  position: relative;
}
.notification__count {
  position: absolute;
  top: 7px;
  right: 5px;
}
.notification-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f5f8fa;
  .left {
    display: flex;
    flex-direction: column;
    p.notification-item__title {
      font-size: 16px;
    }
    p {
      margin: 0;
      padding: 5px;
    }
    .notification-link {
      display: flex;
      align-items: center;
      a {
        margin: 0;
        color: blue;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .right {
    margin-left: 24px;
    &:hover {
      color: red;
      transition: 0.3s;
    }
  }
}
.ant-dropdown-menu-item {
  cursor: auto;
}
</style>
