







import Vue from "vue";
import { mapActions } from "vuex";
import Auth from "@/views/Auth/components/Auth.vue";
import AuthForm from "@/components/forms/AuthForm.vue";
import { Credentials } from "@/views/Auth/models/auth";

export default Vue.extend({
  name: "Login",

  components: {
    AuthForm,
    Auth,
  },

  methods: {
    ...mapActions("auth", ["login"]),

    authenticate(data: Credentials) {
      this.login(data);
    }
  }
});
