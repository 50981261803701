import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_URL;

const client = axios.create({
  timeout: 30000,
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  maxContentLength: 10000,
});

export default client;
