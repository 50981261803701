import {FinancialInformation} from "@/store/modules/financialInforamation/types";

export default {
  discountColumns: [
    {
      title: "Номер",
      dataIndex: "number",
      key: "number",
      width: 100
    },
    {
      title: "Процент скидки",
      dataIndex: "percentage",
      key: "percentage",
    },
    {
      title: "На что предоставляется",
      dataIndex: "provided",
      key: "provided",
    },
    {
      title: "Дата предоставления",
      dataIndex: "date",
      key: "date",
    },
  ],
  paymentColumns: [
    {
      title: "Номер",
      dataIndex: "number",
      key: "number",
      width: 100
    },
    {
      title: "Контрагент",
      dataIndex: "counterparty",
      key: "counterparty",
    },
    {
      title: "Документ",
      dataIndex: "document",
      key: "document",
    },
    {
      title: "Сумма оплаты",
      dataIndex: "payments",
      key: "payments",
    },
    {
      title: "Дата оплаты",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Просрочка",
      dataIndex: "delay",
      key: "delay",
    },
  ],
  financialInformation: {} as FinancialInformation,
};
