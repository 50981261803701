import api from "@/api/employees";
import authApi from "@/api/auth";


export default {
    async addUsersXLSX({ commit }: any, data: object) {
        try {
            await api.addUsersXLSX(data);
        } catch (error) {
            // console.log("ошибка в загрузке файла XLSX");
        }
    },
    async addNewEmployee({ commit }: any, data: object) {
        try {
            const res = await api.addNewEmployee(data);
            return res;
        } catch (error) {
            return { error: error.response.data };
        }
    },
    async updateEmployee({ commit, rootState }: any, data: object) {
      try {
            const res = await api.updateEmployee(data);
            await commit('auth/completeUser', res, { root: true })
            return res;
        } catch (error) {
            return { error: error.response.data };
        }
    },
    async getUsers({ commit }: any, params: object) {
        try {
            const res = await api.getUsersList(params);
            commit('setValue', { users: res });
        } catch (error) {
            // console.log(error);
        }
    },
    async getUser({ commit }: any, id: string) {
        try {
            const res = await api.getUser(id);
            commit('setValue', { user: res });
        } catch (error) {
            // console.log(error);
        }
    },
    async getRoles({ commit }: any, params: object) {
        try {
            const res = await api.getRoles(params);
            commit('setValue', { roles: res });
        } catch (error) {
            // console.log(error);
        }
    },
    async saveRole({ dispatch }: any, roleData: any) {
        try {
            if (roleData.id) {
                await api.patchRole(roleData);
            } else {
                await api.postRole(roleData);
            }
            // dispatch('getRoles');
        } catch (error) {
            // console.log(error);
        }
    },
    async changeRole({ commit }: any, { users, role }: any) {
        try {
            await api.changeRole({ users, role });
        } catch (error) {
            // console.log(error);
        }
    },
    async sendInvitation({ commit }: any, users: any[]) {
        try {
            return api.sendInvitation({ users } as any);
        } catch (error) {
          return Promise.reject(error);
        }
    },
    async getDepartments({ commit }: any, data: object) {
        try {
            const res = await api.getDepartmentsList(data);
            commit('setValue', { departments: res });
        } catch (error) {
          // console.log(error);
        }
    },
    async saveDepartment(context: any, department: { name: string; leader: string, id?: number|string, newItem: boolean }) {
        try {
          if (!department.newItem) {
            await api.patchDepartment(department);
          } else {
            await api.postDepartment(department);
          }
        } catch (error) {
            // console.log(error);
        }
    },
  async getUsersSimplified() {
    try {
      return await api.getUsersSimplified().then((res:any) => res.data);
    } catch (error) {
      return error;
    }
  },
};
