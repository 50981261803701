import axios from 'axios';
import client from '@/api/client';

const BASE_VUE_APP_URL = process.env.VUE_APP_URL;

const catalog = axios.create({
  timeout: 30000,
  baseURL: BASE_VUE_APP_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  maxContentLength: 10000,
});

const BASE_API = 'catalog/v1/catalog';

const NOMENCLATURE_GET_STIGMA_LENS = `v1/orders/stigma_specifications`
const NOMENCLATURE_GET_ASTIGMA_LENS = `v1/orders/astigma_list_specifications`

const NOMENCLATURE_EMPTY_GET_LENSES = `/v1/catalog/products_simplified`
const NOMENCLATURE_EXTENDED_GET_FILTERS = `/v1/catalog/filters?`
const NOMENCLATURE_EXTENDED_GET_LENSES = `${BASE_API}/products?page=`

const NOMENCLATURE_GET_BALANCE_BY_LENSES = `v1/orders/get_balance?`

const SEGMENTS = `${BASE_API}/get_segments`;
const CHANGE_SEGMENTS = `${BASE_VUE_APP_URL}/api/v1/segments/change_vision/`;

const NOMENCLATURE_CHANGE_COUNT_LENSES = `${BASE_VUE_APP_URL}/order/api/v1/orders/warehouse/update/`
const NOMENCLATURE_GET_ORDER = `${BASE_VUE_APP_URL}/order/api/v1/orders/warehouse/load/`
const NOMENCLATURE_GET_ALL_ORDERS = `${BASE_VUE_APP_URL}/order/api/v1/orders/warehouse/total/`
const NOMENCLATURE_DELETE_ORDER = `${BASE_VUE_APP_URL}/order/api/v1/orders/warehouse/delete/`;
const REPEAT_ORDER = `${BASE_VUE_APP_URL}/order/api/v1/orders/warehouse/duplicate/`

export default {
  getNomenclatureStigmaLens(data: object) {
    return client.post(NOMENCLATURE_GET_STIGMA_LENS, { ...data })
  },
  getNomenclatureAstigmaLens(data: object) {
    return client.post(NOMENCLATURE_GET_ASTIGMA_LENS, { ...data })
  },
  getNomenclatureEmptyLenses(data: any) {
    return client.post(`${NOMENCLATURE_EMPTY_GET_LENSES}?client_guid=${data.client_guid}&user_guid=${data.user_guid}`, {
      ordered_earlier_guids: data.ordered_earlier_guids,
    })
  },
  getNomenclatureExtendedFilters(data: any = []) {
    return client.get(`${NOMENCLATURE_EXTENDED_GET_FILTERS}manufacturer[]=${data.manufacturers || ''}&index[]=${data.indexes || ''}&design[]=${data.design || ''}&tint[]=${data.coating || ''}&sun_protect[]=${data.protection || ''}&segment[]=${data.segment || ''}`)

  },
  getNomenclatureExtendedLenses(data: any) {
    return catalog.post(`${NOMENCLATURE_EXTENDED_GET_LENSES}${data.page}`,
      { ...data.filters }
    )
  },
  NomenclatureGetBalanceByLenses(data: any) {
    return client.get(`${NOMENCLATURE_GET_BALANCE_BY_LENSES}spec_id=${data.id}&stash_guid=${data.stash_guid}&dp_id=${data.dp_id}`)
  },
  NomenclatureChangeCountLenses(data: any) {
    return catalog.post(`${NOMENCLATURE_CHANGE_COUNT_LENSES}`,{...data}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('AUTH_TOKEN')}`,
      },
    })
  },
  NomenclatureGetOrder(data: any) {
    return catalog.post(`${NOMENCLATURE_GET_ORDER}`,{...data}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('AUTH_TOKEN')}`,
      },
    })
  },
  NomenclatureGetAllOrders(data: any) {
    return catalog.post(`${NOMENCLATURE_GET_ALL_ORDERS}`,{...data}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('AUTH_TOKEN')}`,
      },
    })
  },
  getSegments(data?: any) {
    return catalog.get(SEGMENTS, { params: data })
  },
  saveSegments(data?: any) {
    return client.post(
      CHANGE_SEGMENTS,
      data
    );
  },
  deleteOrder(order: object) {
    return client.post(NOMENCLATURE_DELETE_ORDER, order);
  },
  repeatOrder(data: object) {
    return client.post(REPEAT_ORDER, data)
  },
}
