
















import Vue from "vue";

export default Vue.extend({
  name: "Icon",
  props: {
    alt: {
      type: String,
      default: "img",
    },
    icon: {
      type: String,
      default: "arrow-left",
    },
    iconHover: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "25px",
    },
    padding: {
      type: String,
      default: "0px",
    },
  },
  data() {
    return {
      active: false,
    };
  },
});
