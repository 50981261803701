export default {
  lens: null,
  deliveryPointSelected: {},

  nomenclatureDeliveryOrders: [],

  nomenclatureEmptyLenses: {},
  nomenclatureEmptyGetOldOrdered: [],

  nomenclatureExtendedFilters: {},
  nomenclatureExtendedFiltersChecked: {
    indexes: [],
    manufacturers: [],
    design: [],
    coating: [],
    protection: [],
    segment: [],
    search: '',
    page: 1,
  },
  nomenclatureExtendedLenses: [],
  nomenclatureExtendedPagination: {
    total: 110,
    page: 1,
    countItem: 10,
  },
  triggerPage: 'warehouse_lens',
  segments: {
    data: [],
    meta: {}
  }
};
