import api from "@/api/cart";

export default {
  async getDeliveryAddresses({commit}: any) {
    try {
      const res: any = await api.getDeliveryAddresses();
      commit("setDeliveryAddresses", res);
    } catch (error) {
      // console.log(error);
    }
  },
  async getDeliveryCompanies({commit}: any) {
    try {
      const res: any = await api.getDeliveryCompanies();
      commit("setDeliveryCompanies", res);
    } catch (error) {
      // console.log(error);
    }
  },
  async updateOrder({dispatch}: any, data: object) {
    try {
      return await api.updateOrder(data);
    } catch (error) {
      return {error: error.response.data};
    }
  },
  async submitOrder({dispatch}: any, data: object) {
    try {
      return await api.submitOrder(data);
    } catch (error) {
      return {error: error.response.data};
    }
  },
  async getOrders({commit}: any, id: string) {
    try {
      const res: object = await api.getOrders(id);
      commit("setOrders", res);
      return res;
    } catch (error) {
      return {error: error.response.data};
    }
  },
  async getNomenclatureEmptyGetOldOrdered({ commit }: any, data: object) {
    try {
      const res = await api.getNomenclatureEmptyGetOldOrdered(data);
      return res.data
    } catch (error) {
      return error
    }
  },
};
