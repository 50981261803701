import client from "@/api/client";

const BASE_API = "api/v1";
const SHORT_BASE_API = "v1";

const GET_COLLECTIONS = `${BASE_API}/meta/get_filter_list/`;
// const GET_META_ROLES = `${BASE_API}/meta/get_roles/`;
const GET_MANAGERS_LIST = `${SHORT_BASE_API}/meta/manager_list`;
const GET_CLIENTS_SEGMENT = `${SHORT_BASE_API}/meta/clients_segment`;
const GET_DELIVERY_POINTS = `${BASE_API}/orders/client_delivery_points/`;

export default {
  getCollections(params?: any) {
    return client.get(GET_COLLECTIONS, { params });
  },
  getClients() {
    return client.get(GET_CLIENTS_SEGMENT);
  },
  getManagersList() {
    return client.get(GET_MANAGERS_LIST);
  },
  getDeliveryPointsList() {
    return client.get(GET_DELIVERY_POINTS);
  },
};
