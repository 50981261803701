<template lang="pug">
  a-layout.container
    Header.header
    .background(v-if="!collapsed" @click="collapse")
    a-layout-sider.sider(
      breakpoint="xl"
      collapsed-width="0"
      :zeroWidthTriggerStyle="{'top':  '11px', 'background': '#005BAA'}"
      width=250
      v-model="collapsed"
    )
      div.logo
        img.logo__img(alt="Logo", src="../assets/img/Logo_MOK.webp")

      a-menu(
        style="overflow: hidden, overflowY: auto",
        :default-open-keys="defaultOpenKeys",
        mode="inline"
      )
        a-menu-item.item(
          v-if="isUserTechAdmin || checkAvailability('acc_main_page')"
          key="home"
        )
          span
            a-icon(type="home")
            span Главная
          router-link(to="/")

        a-menu-item.item(
          key="warehouse-lenses"
          v-if="isWarehouseOrderAccess"
        )
          span
            a-icon(type="appstore")
            span Заказ склад. линз
          router-link(to="/warehouse-lenses")

        a-menu-item.item(
          key="orderRX"
          v-if="isUserTechAdmin || checkAvailability('acc_orders_rx')"
        )
          span
            a-icon(type="file-text")
            span Заказ RX
          router-link(to="/orderRX")

        a-sub-menu.item(
          key="employees"
          v-if="isUserTechAdmin || checkAvailability('emp_adm')"
        )
          span(slot="title")
            a-icon(type="team")
            span.item Сотрудники
          a-menu-item.item(
            v-for="item in employees", :key="item.rout"
              @click="changeCurrentPath(item.rout)"
          ) {{ item.name }}
            router-link(:to="item.rout")

        a-menu-item.item(
          key="roles"
          v-if="isUserTechAdmin || checkAvailability('role_adm')"
          @click="changeCurrentPath('/employees/roles')"
        )
          span
            a-icon(type="control")
            span Роли
          router-link(to="/roles")

        a-sub-menu.item(
          key="clients"
          v-if="isClientsAccess"
        )
          span(slot="title")
            a-icon(type="shop")
            span Клиенты
          a-menu-item.item(
            v-for="item in clients", :key="item.rout"
            @click="changeCurrentPath(item.rout)"
          ) {{ item.name }}
            router-link(:to="item.rout")

        a-menu-item.item(
          key="employeesCopy"
          v-if="user.role_name === 'leader'"
          @click="changeCurrentPath('/clients/employees')"
        )
          span
            a-icon(type="team")
            span Сотрудники
          router-link(to="/clients/employees")

        a-menu-item.item(
          key="points"
          v-if="isClient"
          @click="changeCurrentPath('/clients/delivery-points')"
        )
          span
            a-icon(type="sliders")
            span Салоны оптики
          router-link(to="/clients/delivery-points")

        a-sub-menu.item(
          key="catalog"
          v-if="isWarehouseAccess"
        )
          span(slot="title")
            a-icon(type="read")
            span Каталог
          a-menu-item.item(v-for="item in catalog", :key="item.rout") {{ item.name }}
            router-link(:to="item.rout")

        a-menu-item.item(
          key="history-orders"
          v-if="isUserTechAdmin || checkAvailability('acc_order_history')"
        )
          span
            a-icon(type="unordered-list")
            span История заказов
          router-link(to="/history-orders")

        a-sub-menu.item(
          key="marketingPromotions"
          v-if="isUserTechAdmin || checkAvailability('mrk_prm')"
        )
          span(slot="title")
            a-icon(type="star")
            span.item Маркетинговые акции
          a-menu-item.item(
            v-for="item in marketingPromotions", :key="item.route"
              @click="changeCurrentPath(item.route)"
          ) {{ item.name }}
            router-link(:to="item.route")

        a-menu-item.item(
          key="/marketing-docs"
          v-if="isUserTechAdmin || checkAvailability('mrk_prm')"
        )
          span
            a-icon(type="book")
            span Маркетинговые материалы
          router-link(to="/marketing/materials")

        a-menu-item.item(
          key="/fin-info"
          v-if="isUserTechAdmin || checkAvailability('acc_fin_info')"
        )
          span
            a-icon(type="dollar")
            span Финансовая информация
          router-link(to="/financial-information")

        a-menu-item.item(
          key="/feedback"
          v-if="isUserTechAdmin || checkAvailability('feedback')"
        )
          span
            a-icon(type="message")
            span Обратная связь
          router-link(to="/feedback")

        a-menu-item.item(
          key="/contacts"
          v-if="checkAvailability('acc_contact') && checkAvailability('global_role') === 0"
        )
          span
            a-icon(type="contacts")
            span Контакты
          router-link(to="/contacts")

        a-menu-item.item(
          key="/notifications"
          v-if="checkAvailability('mrk_prm') === 2 && checkAvailability('global_role') !== 0"
        )
          span
            a-icon(type="bell")
            span Уведомления
          router-link(to="/notifications")

    a-layout.custom-layout(:class="{active: collapsed}")
      a-layout-content
        a-row.account-info(v-if="changedAccount")
          div.info-title Вы зашли под аккаунтом: {{ accountName }}
          a-button.quit-button(
            @click="backToAuthorizedAccount"
          ) Выйти
        router-view.main.INNER_CONTENT(:class="{changedAccount: changedAccount}")
</template>

<script>
import Vue from "vue";
import Header from "@/components/Header.vue"
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default Vue.extend({
  name: "HomeLayout",

  components: { Header },

  data () {
    return {
      collapsed: false,
      defaultOpenKeys: ['/'],
      employees: [
        {
          name: 'Список',
          rout: '/employees/list'
        },
        {
          name: 'Подразделения',
          rout: '/employees/subdivision'
        }
      ],
      clients: [
        {
          name: 'Список',
          rout: '/clients/list'
        },
        {
          name: 'Сотрудники',
          rout: '/clients/employees'
        },
        {
          name: 'Салоны оптики',
          rout: '/clients/delivery-points'
        }
      ],
      catalog: [
        {
          name: 'Номенклатура',
          rout: '/catalog-assortment'
        },
        {
          name: 'Сегменты',
          rout: '/catalog-segments'
        },
      ],
      marketingPromotions: [
        {
          name: 'Акции',
          route: '/marketing/promotions'
        },
        {
          name: 'Бонусные коды',
          route: '/marketing/promo-codes'
        }
      ],
    }
  },
  updated () {
    this.selectedKeys = this.$route.path
  },
  computed: {
    ...mapGetters('auth', { permissions: 'getPermissions' }),
    ...mapState('auth', ['user', 'changedAccount']),
    accountName () {
      return this.changedAccount && this.user ?
        `${this.user.lastname} ${this.user.name} ${this.user.middlename} ` :
        '';
    },
    isUserTechAdmin () {
      if (!this.permission) {
        return false;
      }
      return Object.keys(this.permissions).every(item => this.permissions[item]);
    },
    isClient () {
      return this.permissions.global_role === 0;
    },
    isWarehouseOrderAccess () {
      return this.isUserTechAdmin || this.checkAvailability('acc_order_wrh_les') && this.checkAvailability('global_role') !== 1
    },
    isClientsAccess () {
      return this.isUserTechAdmin && this.checkAvailability('global_role') !== 0 || this.checkAvailability('client_adm') && this.checkAvailability('global_role') !== 0
    },
    isWarehouseAccess () {
      return this.isUserTechAdmin || this.checkAvailability('acc_directory') !== 0 && this.checkAvailability('global_role') !== 0
    }
  },

  methods: {
    ...mapMutations('auth', ['changeAuth', 'setChangedAccount']),
    ...mapMutations(['setOwnCurrentPath']),
    ...mapActions('clients', ['getClientEmployeesList']
    ),
    collapse () {
      this.collapsed = !this.collapsed
    },
    backToAuthorizedAccount () {
      this.setChangedAccount(false)
      this.changeAuth();

      const auth = JSON.parse(localStorage.getItem('vuex')).auth
      localStorage.setItem('AUTH_TOKEN', auth.access)
      localStorage.setItem('REFRESH_TOKEN', auth.refresh)
      window.location = window.location.href
    },
    checkAvailability (field) {
      if (!this.permissions) {
        return false;
      }

      const numericalField = ['client_adm', 'warehouse_vis', 'feedback', 'profile_adm'];

      if (numericalField.includes(field)) {
        return Boolean(this.permissions[field]);
      }

      return this.permissions[field];
    },
    changeCurrentPath (val) {
      this.setOwnCurrentPath(val)
      setTimeout(() => {
        this.setOwnCurrentPath('')
      }, 300)
    }
  },

});
</script>

<style lang="scss" scoped>
.logo {
  height: 64px;
  display: none;

  @media screen and (max-width: 500px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;

    .logo__img {
      width: 100%;
      height: auto;
    }
  }
}

.drawer_bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}

.container {
  padding-top: 64px;
  height: 100vh;
  position: relative;

  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
}

.menu {
  width: 224px;
}

.item {
  text-align: start;
}

.account-info {
  background-color: #005baa;
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  padding: 0 24px;

  .info-title {
    line-height: 1.3;
    color: #fff;
    max-width: 74%;
  }

  .quit-button {
    position: absolute;
    right: 24px;
    margin-left: 24px;
  }
}

.custom-layout {
  margin-left: 250px;

  @media screen and (max-width: 1200px) {
    margin-left: 0;
  }

  @media screen and (max-width: 768px) {
    margin-top: 64px;
  }

  &.active {
    margin-left: 0;
  }

  .main {
    animation: opacity 0.3s;
    height: calc(100vh - 64px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 24px;

    @media screen and (max-width: 576px) {
      padding: 24px 10px;
    }
  }
  .main.changedAccount {
    height: calc(100vh - 104px);
  }
}

.sider {
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 50;
  background: #fff;

  @media screen and (max-width: 1200px) and (min-width: 768px) {
    top: 0;
  }
}

.ant-menu {
  border-right-width: 0;
  padding-top: 20px;

  .anticon {
    color: var(--blue-gray);
    transition: color 0.15s ease-in-out;
  }

  &:not(.ant-menu-horizontal) {
    .ant-menu-item-selected,
    .ant-menu-item-active {
      background-color: #f2f7fb;

      .anticon {
        color: currentColor;
      }
    }

    .ant-menu-submenu-open {
      .anticon {
        color: currentColor;
      }
    }
  }
}

.background {
  display: none;

  @media screen and (max-width: 1200px) {
    display: block;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 50;
  }
}
</style>
