import client from '@/api/client';

const BASE_API = 'api/v1';

const CLIENTS_ADD_CLIENTS_XLSX = `${BASE_API}/clients/add_clients/`;
const CLIENTS_GET_CLIENT = `${BASE_API}/clients/full_view/`;
const CLIENTS_GET_CLIENTS = `${BASE_API}/clients/`;
const CLIENTS_CREATE_CLIENT = `${BASE_API}/clients/create_client/`;
const CLIENTS_UPDATE_CLIENT = `${BASE_API}/clients/update_client/`;
const CLIENTS_CHANGE_ROLE = `${BASE_API}/users/change_role/`;

const CLIENTS_GET_EMPLOYEES = `${BASE_API}/clients/employers/`;

const GET_DELIVERY_POINTS = `${BASE_API}/orders/delivery_points/`;
const CREATE_DELIVERY_POINTS = `${BASE_API}/orders/delivery_points/create/`;
const UPDATE_DELIVERY_POINTS = `${BASE_API}/orders/delivery_points/update/`;

const EMPLOYEES_ADD_NEW_EMPLOYEE = `${BASE_API}/users/create_user/`;
const EMPLOYEES_UPDATE_EMPLOYEE = `${BASE_API}/users/update_user/`;
const CLIENTS_GET_LEADERS = `${BASE_API}/clients/get_users/?client=`;

export default {
  addClientsXLSX(data: any) {
    return client.post(CLIENTS_ADD_CLIENTS_XLSX, data);
  },
  getClient(data: object) {
    return client.get(CLIENTS_GET_CLIENT, { params: data });
  },
  getClientsList(data: object) {
    return client.get(CLIENTS_GET_CLIENTS, { params: data });
  },
  createClient(data: object) {
    return client.post(CLIENTS_CREATE_CLIENT, data);
  },
  updateClient(data: object) {
    return client.post(CLIENTS_UPDATE_CLIENT, data);
  },
  changeRole(data: object) {
    return client.post(CLIENTS_CHANGE_ROLE, data);
  },
  getClientEmployeesList(params: object) {
    return client.get(CLIENTS_GET_EMPLOYEES, { params });
  },
  postClientsEmployee(data: object) {
    return client.post(EMPLOYEES_ADD_NEW_EMPLOYEE, data);
  },
  patchClientsEmployee(data: object) {
    return client.post(EMPLOYEES_UPDATE_EMPLOYEE, data);
  },

  getDeliveryPointsList(params: any) {
    return client.get(GET_DELIVERY_POINTS, { params });
  },
  postDeliveryPoint(data: object) {
    return client.post(CREATE_DELIVERY_POINTS, data);
  },
  patchDeliveryPoint(data: object) {
    return client.post(UPDATE_DELIVERY_POINTS, data);
  },
  getLeaders(data: any) {
    return client.get(`${CLIENTS_GET_LEADERS}${data}`);
  },
}
