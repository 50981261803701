export default {
  promotion: {
    title: '',
    descr: '',
  },
  promotions: [],
  promoCodes: [],
  selectedPromoCodes: [],
  // errors: [],
};
